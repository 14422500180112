import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Popup from 'reactjs-popup';
import Alert from 'react-bootstrap/Alert';
import "react-datepicker/dist/react-datepicker.css";
import 'reactjs-popup/dist/index.css';
import { ReactComponent as IconPlus } from '../icons/ico-plus.svg';
import { ReactComponent as IconPhoto } from '../icons/ico-photo.svg';
import { ReactComponent as IconVideo } from '../icons/ico-video.svg';
import { ReactComponent as IconVoice } from '../icons/ico-voice.svg';
import { ReactComponent as IconCamera } from '../icons/ico-camera.svg';
import { ReactComponent as IconAttachment } from '../icons/ico-attachment.svg';
import { ReactComponent as IconClose } from '../icons/ico-close.svg';
import '../css/note.css';
import {
    addNote
} from '../slices/userSlice';
import LoadingOverlay from 'react-loading-overlay-ts';
import BounceLoader from 'react-spinners/BounceLoader';
import { toast } from 'react-toastify';
import { resetNoteSubmit, setAddNote } from '../slices/userSlice';
import VideoThumbnail from 'react-video-thumbnail';
import BrowserCamera from './BrowserCamera';
// import VoiceRecorder from './VoiceRecorder';
// import VoiceRecorder2 from './VoiceRecorder2';
// import VoiceRecorder3 from './VoiceRecorder3';
// import AudioRecorder from './AudioRecorder';
import { isBase64 } from '../helpers/generalFunctions';

const AddNote = (props) => {

    const userState = useSelector(state => state.user);

    const dispatch = useDispatch();

    const [validated, setValidated] = useState(false);
    const [title, setTitle] = useState('');
    const [note, setNote] = useState('');

    const [cameraOpen, setCameraOpen] = useState(false);
    const [voiceOpen, setVoiceOpen] = useState(false);
    const [fileStorage, setFileStorage] = useState([]);
    const uploadPhotoRef = useRef(null);
    const uploadVideoRef = useRef(null);

    const openAddNotePopup = () => {
        dispatch(resetNoteSubmit());
        dispatch(setAddNote());
    }

    const closeAddPopup = () => {
        setValidated(false);
        setTitle('');
        setNote('');

        dispatch(resetNoteSubmit());
    }

    const getParams = () => {

        let params = {};
        
        if( title ) params.title = title;
        if( note ) params.note = note;

        if (fileStorage) {
            fileStorage.map((file) => {
                if(file.type.match("image")){
                    if( typeof params.photos === 'undefined' ){
                        params.photos = [];
                    }
                    params.photos.push(file);
                }else if(file.type.match("video")){
                    if( typeof params.videos === 'undefined' ){
                        params.videos = [];
                    }
                    params.videos.push(file);
                }else if(file.type.match("audio")){
                    if( typeof params.voices === 'undefined' ){
                        params.voices = [];
                    }
                    params.voices.push(file);
                }
            });
        }


        return params;
    }

    const handleSubmit = (note) => {

        note.preventDefault();

        let params = getParams();

        if( ! userState.note.isAddLoading ){

            setValidated(true);
            
            const form = note.currentTarget;
            
            if (form.checkValidity() === false) {
                note.stopPropagation();
            }

            if(form.checkValidity() === true) {             
                // console.log('params', params)
                dispatch(addNote(params));
            }
        }
    }

    useEffect(()=>{
        if(userState.note.addSuccess && ! userState.note.isAddLoading){

            toast.success(() => 'Note Added', {
                toastId: 'add-note-success',
                position: "top-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                onClose: () => {
                    window.location.reload(false);
                }
            }); 
            
            dispatch(resetNoteSubmit());
        }
    }, [userState.note.addSuccess])

    useEffect(() => {

        if( userState.note.addErrorMsg ){
            dispatch(resetNoteSubmit());

            toast.error(userState.note.addErrorMsg, {
                toastId: 'add-note-error',
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });            
        }
        
    }, [userState.note.addErrorMsg])

    const mediaUploadHandler = (event) => {
        if( typeof event.target.files[0] !== 'undefined'){
            // console.log(event.target.files[0]);
            setFileStorage([...fileStorage, ...[event.target.files[0]]]);
        }
    }

    const stopCamera = () => {
        setCameraOpen(false);
    }

    const stopRecording = () => {
        setVoiceOpen(false);
    }

    const addMediaToStorage = (file) => {
        setFileStorage([...fileStorage, ...[file]]);
    }

    const removeMedia = (index) => {
        let array = fileStorage; // make a separate copy of the array
        console.log('index', index);
        console.log('remove', array);
        array.splice(index, 1);
        setFileStorage([...array]);
    }

    const addNotePopupElement = () => {

        if(!userState.note.addNote){
            return (<></>);
        }
        
        return (
            <>
            {cameraOpen && <BrowserCamera stopCamera={stopCamera} addMediaToStorage={addMediaToStorage} />}
            {/* {voiceOpen && <VoiceRecorder2 stopRecording={stopRecording} addMediaToStorage={addMediaToStorage} />} */}
            <Popup open={userState.note.addNote} position="center center" closeOnDocumentClick={false}>
                <div className="form-wrap">
                    <h3>Add Note</h3>
                    <Form noValidate validated={validated} className="popup-form form-add-note col-12" onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="title">
                            <Form.Control type="text" placeholder="Title" required={true} value={title} onChange={(note)=>setTitle(note.target.value)} />
                            <Form.Control.Feedback type="invalid">
                                Please enter title
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3 mb-5" controlId="note_note">
                            <div className="note-wrap"> 
                                <Form.Control as="textarea" rows={8} placeholder="Note" required={true} value={note} onChange={(note)=>setNote(note.target.value)} />
                                <ul className="action-wrap">
                                    <li><IconCamera onClick={() => setCameraOpen(true)} /></li>
                                    <li><IconVideo onClick={() => uploadVideoRef.current.click()} /><input style={{display:'none'}} ref={uploadVideoRef} type="file" accept="video/*" onChange={(event) => mediaUploadHandler(event)} /></li>
                                    <li><IconPhoto onClick={() => uploadPhotoRef.current.click()} /><input style={{display:'none'}} ref={uploadPhotoRef} type="file" accept="image/*" onChange={(event) => mediaUploadHandler(event)} /></li>
                                    <li><IconVoice onClick={() => setVoiceOpen(true)} /></li>
                                    {/* <li><IconAttachment /></li> */}
                                </ul>
                            </div>
                            <Form.Control.Feedback type="invalid">
                                Please enter note
                            </Form.Control.Feedback>
                        </Form.Group>

                        <div className="thumb-wrap">
                            {/* {console.log(fileStorage)} */}
                            <ul className='media-thumb'>
                                {fileStorage && fileStorage.map((file, index) => {
                                    let url = URL.createObjectURL(file);

                                    if (file.type.match("video")) {
                                        let videoThumb = typeof file.thumbBase64 !== 'undefined' ? file.thumbBase64 : require('../images/play-button.webp');
                                        return <li key={index}>
                                            <VideoThumbnail videoUrl={url} renderThumbnail={true} thumbnailHandler={(thumbnail) => {
                                                if(isBase64(thumbnail)) {
                                                    file.thumbBase64 = thumbnail;
                                                    setFileStorage([...fileStorage]);
                                                }
                                            }} />
                                            <img alt="not fount" src={videoThumb} />                                            
                                            <IconClose className="btn-remove" onClick={() => removeMedia(index)} />
                                        </li>;                                
                                    } else if (file.type.match("image")) {
                                        return <li key={index}>
                                            <img alt="not fount" src={url} />
                                            <IconClose className="btn-remove" onClick={() => removeMedia(index)} />
                                        </li>;
                                    } else if (file.type.match("audio")) {
                                        return <li key={index}>
                                            <img alt="not fount" src={require('../images/voice.jpg')} />
                                            <IconClose className="btn-remove" onClick={() => removeMedia(index)} />
                                        </li>;
                                    }
                                })}
                            </ul>
                        </div>

                        {userState.note.addErrorMsg && <Alert className="mt-3" variant="danger">{userState.note.addErrorMsg}</Alert>}
                        
                        <a className="close-popup" onClick={()=> closeAddPopup()}>Cancel</a>

                        <Button variant="primary" type="submit">
                            Create Note
                        </Button>
                    </Form>
                </div>
            </Popup>
            </>
        );
    }

    return (
        <>
            <LoadingOverlay
                active={userState.note.isAddLoading}
                // active={true}
                // spinner={<BounceLoader />}
                text=""
                >                    
            </LoadingOverlay>

            {addNotePopupElement()}

            {! userState.note.addNote &&
            <div className="fixed-add-wrap">
                <span id="add" className="button" onClick={() => openAddNotePopup()}><IconPlus /></span>
            </div>}
        </>
    )
}

export default AddNote;