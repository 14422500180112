import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import '../css/dashboard.css';
import { confirmAlert } from 'react-confirm-alert';

import { ReactComponent as IconBell } from '../icons/ico-bell.svg';
import { ReactComponent as IconUser } from '../icons/ico-user-3.svg';
import { ReactComponent as IconNewsletter } from '../icons/ico-newsletter.svg';
import { ReactComponent as IconInformation } from '../icons/ico-information.svg';
import { ReactComponent as IconDocument } from '../icons/ico-document.svg';
import { ReactComponent as IconMoney } from '../icons/ico-money.svg';
import { ReactComponent as IconResource } from '../icons/ico-resource.svg';
import { ReactComponent as IconEvent } from '../icons/ico-event.svg';
import { ReactComponent as IconBook } from '../icons/ico-book-2.svg';
import { ReactComponent as IconCloud } from '../icons/ico-cloud.svg';
import { ReactComponent as IconExclamation } from '../icons/ico-exclamation.svg';
import LazyLoad from 'react-lazy-load';
import Alert from 'react-bootstrap/Alert';
import { isConfirmationPopupShowed, isProfileComplete, profileCompletionReminder } from '../helpers/generalFunctions';
import { confirmationPopupShow, setNextReminder } from '../slices/userSlice';
import { ReactComponent as Logo } from '../images/logo.svg';
import { Button } from 'react-bootstrap';

const Dashboard = (props) => {

    const dispatch = useDispatch();
    const userState = useSelector(state => state.user);
    const [more, setMore] = useState(false);
    const [redirectTo, setRedirectTo] = useState(null);
    // const [profileReminder, setProfileReminder] = useState(true);
    // console.log('user',userState.user);

    const ProfileReminder = () => {
        if (!isProfileComplete(userState.user) && profileCompletionReminder(userState.user)) {
            // console.log(userState.user);
            if (userState.user.profileReminderCount === 0) {
                return <Alert className="" variant="warning">Please complete your <Link to="/profile">profile</Link> <a href="#" className="btn-close" onClick={() => { dispatch(setNextReminder()); }}></a></Alert>;
            } else {
                return <Alert className="" variant="warning">Please confirm that your <Link to="/profile">profile</Link> details are correct <a href="#" className="btn-close" onClick={() => { dispatch(setNextReminder()); }}></a></Alert>;
            }
        }

        return <></>;
    }

    if (redirectTo) {
        return <Navigate to={redirectTo} />
    }

    return (
        <div className="dashboard-wrap row">

            <div className="logo-wrap col-12">
                <Logo />
                {/* <LazyLoad> */}
                {/* <img id="logo" src={require('../images/logo2.png')} alt="DJMIR" /> */}
                {/* </LazyLoad> */}
            </div>

            <div className="alert-wrap">
                {/* <ProfileReminder /> */}
            </div>

            <div className="options">

                <ul>
                    <li>
                        <Link to="/alerts"><div className="icon-wrap"><IconBell className="svg-ico svg-ico-25 svg-ico-bell" /></div><span className="title">Alerts</span></Link>
                        {userState.notification.count > 0 && <span className="alert-count">{userState.notification.count}</span>}
                    </li>
                    <li className={!userState.isLoggedIn ? 'disabled' : null}><Link to="" onClick={(event) => {

                        event.preventDefault();

                        if (!userState.isLoggedIn) {
                            setRedirectTo('/login');
                            return;
                        }

                        let a = document.createElement('a');
                        a.target = '_blank';
                        a.href = 'https://www.djmir.com.au/newsletters';

                        if (!isConfirmationPopupShowed()) {
                            confirmAlert({
                                title: 'Confirmation',
                                message: <div>Proceed to open this link in a separate browser window?<span className="txt-direction"><IconExclamation width={16} height={16} /> After viewing content, return to the main app window by clicking the 'X' or 'Done' button in the top left corner, or close the tab if using the app in a browser.</span></div>,
                                buttons: [
                                    {
                                        label: 'Yes', onClick: () => {
                                            dispatch(confirmationPopupShow());
                                            a.click();
                                        }
                                    },
                                    { label: 'No', onClick: () => { } }
                                ]
                            });
                        } else {
                            a.click();
                        }
                    }}><div className="icon-wrap"><IconNewsletter className="svg-ico svg-ico-25 svg-ico-newsletter" /></div><span className="title">Newsletters</span></Link></li>
                    <li><Link to="" onClick={(event) => {

                        event.preventDefault();

                        let a = document.createElement('a');
                        a.target = '_blank';
                        a.href = 'https://www.djmir.com.au/what-we-do';

                        if (!isConfirmationPopupShowed()) {
                            confirmAlert({
                                title: 'Confirmation',
                                message: <div>Proceed to open this link in a separate browser window?<span className="txt-direction"><IconExclamation width={16} height={16} /> After viewing content, return to the main app window by clicking the 'X' or 'Done' button in the top left corner, or close the tab if using the app in a browser.</span></div>,
                                buttons: [
                                    {
                                        label: 'Yes', onClick: () => {
                                            dispatch(confirmationPopupShow());
                                            a.click();
                                        }
                                    },
                                    { label: 'No', onClick: () => { } }
                                ]
                            });
                        } else {
                            a.click();
                        }
                    }}><div className="icon-wrap"><IconInformation className="svg-ico svg-ico-25 svg-ico-information" /></div><span className="title">What we do</span></Link></li>
                    <li className={!userState.isLoggedIn ? 'disabled' : null}>
                        <Link to="" onClick={(event) => {

                            event.preventDefault();

                            if (!userState.isLoggedIn) {
                                setRedirectTo('/login');
                                return;
                            }

                            let a = document.createElement('a');
                            a.target = '_blank';
                            a.href = 'https://www.djmir.com.au/documents';

                            if (!isConfirmationPopupShowed()) {
                                confirmAlert({
                                    title: 'Confirmation',
                                    message: <div>Proceed to open this link in a separate browser window?<span className="txt-direction"><IconExclamation width={16} height={16} /> After viewing content, return to the main app window by clicking the 'X' or 'Done' button in the top left corner, or close the tab if using the app in a browser.</span></div>,
                                    buttons: [
                                        {
                                            label: 'Yes', onClick: () => {
                                                dispatch(confirmationPopupShow());
                                                a.click();
                                            }
                                        },
                                        { label: 'No', onClick: () => { } }
                                    ]
                                });
                            } else {
                                a.click();
                            }
                        }}><div className="icon-wrap"><IconDocument className="svg-ico svg-ico-25 svg-ico-document" /></div><span className="title">Private Documents</span></Link>
                    </li>
                    <li>
                        <Link to="" onClick={(event) => {

                            event.preventDefault();

                            // if(!userState.isLoggedIn){
                            //     setRedirectTo('/login');
                            //     return;
                            // }

                            let a = document.createElement('a');
                            a.target = '_blank';
                            a.href = 'https://www.djmir.com.au/Public-Documents';

                            if (!isConfirmationPopupShowed()) {
                                confirmAlert({
                                    title: 'Confirmation',
                                    message: <div>Proceed to open this link in a separate browser window?<span className="txt-direction"><IconExclamation width={16} height={16} /> After viewing content, return to the main app window by clicking the 'X' or 'Done' button in the top left corner, or close the tab if using the app in a browser.</span></div>,
                                    buttons: [
                                        {
                                            label: 'Yes', onClick: () => {
                                                dispatch(confirmationPopupShow());
                                                a.click();
                                            }
                                        },
                                        { label: 'No', onClick: () => { } }
                                    ]
                                });
                            } else {
                                a.click();
                            }
                        }}><div className="icon-wrap"><IconDocument className="svg-ico svg-ico-25 svg-ico-document" /></div><span className="title">Public Documents</span></Link>
                    </li>
                    <li className={!userState.isLoggedIn ? 'disabled' : null}><Link to="/pay-rates"><div className="icon-wrap"><IconMoney className="svg-ico svg-ico-25 svg-ico-money" /></div><span className="title">Pay rates</span></Link></li>
                    <li className={!userState.isLoggedIn ? 'disabled' : null}><Link to="" onClick={(event) => {

                        event.preventDefault();

                        if (!userState.isLoggedIn) {
                            setRedirectTo('/login');
                            return;
                        }

                        let a = document.createElement('a');
                        a.target = '_blank';
                        a.href = 'https://www.djmir.com.au/Training-and-Professional-Development';

                        if (!isConfirmationPopupShowed()) {
                            confirmAlert({
                                title: 'Confirmation',
                                message: <div>Proceed to open this link in a separate browser window?<span className="txt-direction"><IconExclamation width={16} height={16} /> After viewing content, return to the main app window by clicking the 'X' or 'Done' button in the top left corner, or close the tab if using the app in a browser.</span></div>,
                                buttons: [
                                    {
                                        label: 'Yes', onClick: () => {
                                            dispatch(confirmationPopupShow());
                                            a.click();
                                        }
                                    },
                                    { label: 'No', onClick: () => { } }
                                ]
                            });
                        } else {
                            a.click();
                        }
                    }}><div className="icon-wrap"><IconResource className="svg-ico svg-ico-25 svg-ico-resource" /></div><span className="title">PD Resources</span></Link></li>
                    <li><Link to="" onClick={(event) => {

                        event.preventDefault();

                        let a = document.createElement('a');
                        a.target = '_blank';
                        a.href = 'https://www.djmir.com.au/Early-Learning-Workshops-2022';

                        if (!isConfirmationPopupShowed()) {
                            confirmAlert({
                                title: 'Confirmation',
                                message: <div>Proceed to open this link in a separate browser window?<span className="txt-direction"><IconExclamation width={16} height={16} /> After viewing content, return to the main app window by clicking the 'X' or 'Done' button in the top left corner, or close the tab if using the app in a browser.</span></div>,
                                buttons: [
                                    {
                                        label: 'Yes', onClick: () => {
                                            dispatch(confirmationPopupShow());
                                            a.click();
                                        }
                                    },
                                    { label: 'No', onClick: () => { } }
                                ]
                            });
                        } else {
                            a.click();
                        }
                    }}><div className="icon-wrap"><IconEvent className="svg-ico svg-ico-25 svg-ico-event" /></div><span className="title">Seminar & workshops</span></Link></li>
                    <li><Link to="" onClick={(event) => {

                        event.preventDefault();

                        let a = document.createElement('a');
                        a.target = '_blank';
                        a.href = 'https://www.djmir.com.au/Resources-and-Materials/';

                        if (!isConfirmationPopupShowed()) {
                            confirmAlert({
                                title: 'Confirmation',
                                message: <div>Proceed to open this link in a separate browser window?<span className="txt-direction"><IconExclamation width={16} height={16} /> After viewing content, return to the main app window by clicking the 'X' or 'Done' button in the top left corner, or close the tab if using the app in a browser.</span></div>,
                                buttons: [
                                    {
                                        label: 'Yes', onClick: () => {
                                            dispatch(confirmationPopupShow());
                                            a.click();
                                        }
                                    },
                                    { label: 'No', onClick: () => { } }
                                ]
                            });
                        } else {
                            a.click();
                        }
                    }}><div className="icon-wrap"><IconBook className="svg-ico svg-ico-25 svg-ico-book" /></div><span className="title">Resources & materials</span></Link></li>
                    <li className={!userState.isLoggedIn ? 'disabled' : null}><Link to="/profile"><div className="icon-wrap"><IconUser className="svg-ico svg-ico-25 svg-ico-user" /></div><span className="title">Your service profile</span></Link></li>
                    <li><Link to="/links" onClick={(event) => {

                        event.preventDefault();

                        let a = document.createElement('a');
                        a.target = '_blank';
                        a.href = 'https://www.djmir.com.au/Helpful-Links/';

                        if (!isConfirmationPopupShowed()) {
                            confirmAlert({
                                title: 'Confirmation',
                                message: <div>Proceed to open this link in a separate browser window?<span className="txt-direction"><IconExclamation width={16} height={16} /> After viewing content, return to the main app window by clicking the 'X' or 'Done' button in the top left corner, or close the tab if using the app in a browser.</span></div>,
                                buttons: [
                                    {
                                        label: 'Yes', onClick: () => {
                                            dispatch(confirmationPopupShow());
                                            a.click();
                                        }
                                    },
                                    { label: 'No', onClick: () => { } }
                                ]
                            });
                        } else {
                            a.click();
                        }
                    }}><div className="icon-wrap"><IconCloud className="svg-ico svg-ico-25 svg-ico-cloud" /></div><span className="title">Helpful links</span></Link></li>
                    {/*<li><a href="https://www.djmir.com.au/widget/djmir-events" onClick={(event) => {

                        event.preventDefault();
                        
                        confirmAlert({
                            title: 'Confirmation',
                            message: <div>Proceed to open this link in a separate browser window?<span className="txt-direction"><IconExclamation width={16} height={16} /> After viewing content, return to the main app window by clicking the 'X' or 'Done' button in the top left corner, or close the tab if using the app in a browser.</span></div>,
                            buttons: [
                                { label: 'Yes', onClick: () => {
                                    // window.open('https://www.djmir.com.au/widget/djmir-events','popup', "width="+Screen.availWidth+",height="+ Screen.availHeight);
                                    // window.open('https://www.djmir.com.au/widget/djmir-events','_blank', 'toolbar=yes');
                                    // window.location.href='https://www.djmir.com.au/widget/djmir-events';

                                    let a= document.createElement('a');
                                    a.target= '_blank';
                                    a.href= 'https://www.djmir.com.au/widget/djmir-events';
                                    a.click();
                                } },
                                { label: 'No', onClick: () => {} }
                            ]
                        });
                    }} ><div className="icon-wrap"><IconGear className="svg-ico svg-ico-25 svg-ico-gear" /></div><span className="title">UFU Events</span></a></li>*/}
                    <li></li>
                </ul>
            </div>

            {/* <p className="login-intro">Please login to access more features</p> */}
            {!userState.isLoggedIn && <Link to="/login"><Button className="f-width mt-3" variant="primary">Login</Button></Link>}
        </div>
    )
}

export default Dashboard;