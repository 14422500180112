import axios from 'axios';
import { BASE_API_URL, API_KEY, DEV_MODE } from './constants';

// const userState.credentials = JSON.parse(localStorage.getItem("userState.credentials"));

export const authenticate = (email, password) => {

    return axios.post(BASE_API_URL + "authenticate", {
        username: email,
        password: password,
        api_key: API_KEY,
    });
};

export const sso_authenticate = (code, redirect_uri) => {

    return axios.post(BASE_API_URL + "sso_authenticate", {
        code: code,
        redirect_uri: redirect_uri,
        api_key: API_KEY,
    });
};

export const create_event = (params) => {

    params.api_key = API_KEY;

    var form_data = new FormData();

    for ( var key in params ) {
        if(Array.isArray(params[key])){
            params[key].map((file) => {
                
                form_data.append(key + '[]',file , file.name);
            });
        }else 
            form_data.append(key, params[key]);
    }

    // console.log(form_data);

    return axios.post(BASE_API_URL + "events", form_data, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};

export const update_event = (params) => {

    params.api_key = API_KEY;

    var form_data = new FormData();

    for ( var key in params ) {
        if(Array.isArray(params[key])){
            params[key].map((file) => {
                // console.log('file',file['type']);
                if(typeof file['type'] !== 'undefined'){
                    form_data.append(key + '[]',file , file.name);
                }else 
                    form_data.append( key + '[]', file);
                
            });
        }else 
            form_data.append(key, params[key]);
    }
    // console.log('form_data', form_data);

    return axios.post(BASE_API_URL + "events", form_data, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};

export const delete_event = (event_id, params) => {

    params.api_key = API_KEY;

    let url = new URL(BASE_API_URL + "event/" + event_id);
    url.search = new URLSearchParams(params);

    return axios.delete(url.toString(), params);
};

export const get_events = (params, cancelToken) => {

    params.api_key = API_KEY;

    // console.log(JSON.stringify(params));

    let url = new URL(BASE_API_URL + "events");
    url.search = new URLSearchParams(params);

    // console.log(url.toString(), 'url');

    return axios.get(url.toString(), {
        cancelToken
    });

    // return axios({
    //     method: 'get',
    //     url: url.toString() , 
    //     // params: {
    //     //     year: params.year,
    //     //     month: params.month,
    //     //     api_key: API_KEY,
    //     //     refresh_token: credentials.refresh_token,
    //     // },
    //     headers: {
    //         // "Content-Type": "application/json",
    //     },
    // });
};

export const get_documents = (search, refresh_token) => {
    let url = BASE_API_URL + "documents/?api_key=" + API_KEY + "&refresh_token=" + refresh_token;
    if(search){
        url += '&search=' + search;
    }
    return axios.get(url);
}

export const get_bulletins = (search, refresh_token) => {
    let url = BASE_API_URL + "bulletins/?api_key=" + API_KEY + "&refresh_token=" + refresh_token;
    if(search){
        url += '&search=' + search;
    }
    return axios.get(url);
}

export const get_locations = () => {
    let url = BASE_API_URL + "locations/?api_key=" + API_KEY;
   
    return axios.get(url);
}

export const get_services = () => {
    let url = BASE_API_URL + "services/?api_key=" + API_KEY;
   
    return axios.get(url);
}

export const get_contacts = () => {
    let url = BASE_API_URL + "contacts/?api_key=" + API_KEY;
   
    return axios.get(url);
}

export const get_notifications = (params) => {

    params.api_key = API_KEY;

    let url = new URL(BASE_API_URL + "notifications");
    url.search = new URLSearchParams(params);

    return axios.get(url.toString());
}

export const notification_seen = (params) => {

    params.api_key = API_KEY;

    return axios.post(BASE_API_URL + "notification/set_as_seen", params);
}

export const get_notes = (search, refresh_token) => {
    let url = BASE_API_URL + "notes/?api_key=" + API_KEY + "&refresh_token=" + refresh_token;
    if(search){
        url += '&search=' + search;
    }
    return axios.get(url);
}

export const create_note = (params) => {

    params.api_key = API_KEY;

    var form_data = new FormData();

    for ( var key in params ) {
        if(Array.isArray(params[key])){
            params[key].map((file) => {
                
                form_data.append(key + '[]',file , file.name);
            });
        }else 
            form_data.append(key, params[key]);
    }

    // console.log(params);

    return axios.post(BASE_API_URL + "notes", form_data);
};

export const update_note = (params) => {

    params.api_key = API_KEY;

    var form_data = new FormData();

    for ( var key in params ) {
        if(Array.isArray(params[key])){
            params[key].map((file) => {
                // console.log('file',file['type']);
                if(typeof file['type'] !== 'undefined'){
                    form_data.append(key + '[]',file , file.name);
                }else 
                    form_data.append( key + '[]', file);
                
            });
        }else 
            form_data.append(key, params[key]);
    }
    // console.log('form_data', form_data);

    return axios.post(BASE_API_URL + "notes", form_data, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};

export const delete_note = (note_id, params) => {

    params.api_key = API_KEY;

    let url = new URL(BASE_API_URL + "note/" + note_id);
    url.search = new URLSearchParams(params);

    return axios.delete(url.toString(), params);
};

export const get_videos = (page) => {
    let url = BASE_API_URL + "videos/?api_key=" + API_KEY + "&type=other";
    if(page){
        url += '&page=' + page;
    }
    return axios.get(url);
}

export const get_flames = (page) => {
    let url = BASE_API_URL + "videos/?api_key=" + API_KEY + "&type=flames";
    if(page){
        url += '&page=' + page;
    }
    return axios.get(url);
}

export const get_profile = (params) => {

    params.api_key = API_KEY;

    return axios.post(BASE_API_URL + "profile/get", params);
}

export const save_profile = (params) => {

    params.api_key = API_KEY;

    return axios.post(BASE_API_URL + "profile/save", params);
}

export const add_webpushnotification_endpoint = (params) => {

    params.api_key = API_KEY;

    return axios.post(BASE_API_URL + "user/push_notification", params);
}

export const get_sync_status = (params) => {

    params.api_key = API_KEY;

    return axios.post(BASE_API_URL + "user/sync_status", params);
}

export const run_google_sync = (params) => {

    params.api_key = API_KEY;

    return axios.post(BASE_API_URL + "user/google_sync", params);
}

export const remove_google_events = (params) => {

    params.api_key = API_KEY;

    return axios.post(BASE_API_URL + "user/remove_google_events", params);
}

export const set_google_token = (params) => {

    params.api_key = API_KEY;

    if( DEV_MODE ){
        params.dev = true;
    }

    return axios.post(BASE_API_URL + "user/google_token", params);
}

export const revoke_google_token = (params) => {

    params.api_key = API_KEY;

    return axios.post(BASE_API_URL + "user/revoke_google_token", params);
}

export const remove_media = (params) => {

    params.api_key = API_KEY;

    return axios.post(BASE_API_URL + "user/remove_media", params);
}

export const set_next_reminder = (params) => {

    params.api_key = API_KEY;

    return axios.post(BASE_API_URL + "reminder/set_new", params);
}

export const forgot_username = (params) => {

    params.api_key = API_KEY;

    return axios.post(BASE_API_URL + "user/forgot_username", params);
};

export const submit_contact_form = (params) => {

    params.api_key = API_KEY;

    return axios.post(BASE_API_URL + "tickets/add", params);
}

export const get_pay_rates = () => {

    return axios.get(BASE_API_URL + "pay_rates?api_key=" + API_KEY);
}

export const get_pay_rates_pdf = (params) => {

    params.api_key = API_KEY;

    return axios.post(BASE_API_URL + "pay_rates_pdf?api_key=", params);
}

export const get_pdf_download_url = (params) => {

    params.api_key = API_KEY;

    // console.log('params', params)

    return axios.post(BASE_API_URL + "pay_rates/download", params);
}

export const send_rate_mail = (params) => {

    params.api_key = API_KEY;

    return axios.post(BASE_API_URL + "pay_rates/mail", params);
}

export const send_pdf_mail = (params) => {

    params.api_key = API_KEY;

    return axios.post(BASE_API_URL + "pay_rates/send_pdf", params);
}

export const get_service_number_options = (providerNumber) => {

    let params = {};
    params.provider_number = providerNumber;
    params.api_key = API_KEY;

    return axios.post(BASE_API_URL + "service_number/get_options", params);
}

export const search_user_data = (params) => {

    params.api_key = API_KEY;

    return axios.post(BASE_API_URL + "service_number/search", params);
}

export const regist_user = (params) => {

    params.api_key = API_KEY;

    return axios.post(BASE_API_URL + "user/regist", params);
}
