import React, {useEffect, useState} from 'react';
// import Form from 'react-bootstrap/Form';
// import { ReactComponent as IconSearch } from '../icons/ico-search.svg';
import { ReactComponent as IconGear } from '../icons/ico-gear.svg';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { get_services } from '../helpers/api.service';
import { ReactComponent as IconExclamation } from '../icons/ico-exclamation.svg';

const Service = (props) => {
    const [data, setData] = useState(null);

    useEffect(() => {
        get_services().then(res => {
            if(res.data.data.services!=null){        
                setData(res.data.data.services);
            }
        });
    },[])

    return (
        <div className="row">           
            <div className="col-12">
                <h1 className="page-title">Services</h1>

                <div className="item-list list-service">
                    {data === null && <p className="text-center">Loading...</p>}
                    {data !== null && data.map((service, index) => {
                        let iframePage =  `/external/?title=${service.name}&url=` + btoa(service.url);
                        // console.log(service);
                        return(
                            <li key={index}>
                                <div className="item-header text-decoration-underline">
                                    <span className="item-icon highlight"><IconGear /></span>
                                    {service.exclude_iframe === 1 ?
                                    <a target="popup" onClick={(event) => { 
                                        
                                        event.preventDefault(); 

                                        confirmAlert({
                                            title: 'Confirmation',
                                            message: <div>Proceed to open this link in a separate browser window?<span className="txt-direction"><IconExclamation width={16} height={16} /> After viewing content, return to the main app window by clicking the 'X' or 'Done' button in the top left corner, or close the tab if using the app in a browser.</span></div>,
                                            buttons: [
                                                { label: 'Yes', onClick: () => {
                                                    // window.open(service.url,'popup', "width="+Screen.availWidth+",height="+ Screen.availHeight);
                                                    // window.open(service.url,'_blank', 'toolbar=yes');

                                                    let a= document.createElement('a');
                                                    a.target= '_blank';
                                                    a.href= service.url;
                                                    a.click();
                                                } },
                                                { label: 'No', onClick: () => {} }
                                            ]
                                        });
                                    }} rel="noreferrer" href={service.url}>{service.name}</a>
                                    :
                                    <Link to={iframePage}>{service.name}</Link>}
                                </div>
                            </li>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default Service;