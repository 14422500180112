import './App.css';
import './css/map.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Hook from './helpers/Hook';

//page components
import Page from './components/Page';
import Login from './components/Login';
import Logout from './components/Logout';
import Verify from './components/Verify';
// import Register from './components/RegisterStep1';
import Profile from './components/Profile';
import Dashboard from './components/Dashboard';
import Hotline from './components/Hotline';
import Alert from './components/Alert';
import SingleAlert from './components/SingleAlert';
import ContactForm from './components/ContactForm';
import PayRates from './components/PayRates';
import Newsletter from './components/Newsletter';
import WhatWeDo from './components/WhatWeDo';
import Document from './components/Document';
import PDResource from './components/PDResource';
import Seminar from './components/Seminar';
import Resource from './components/Resource';
import Links from './components/Links';
import NotFound from './components/NotFound';

import Calendar from './components/Calendar';
import Bulletin from './components/Bulletin';
import Contact from './components/Contact';
import Video from './components/Video';
import Location from './components/Location';
import Service from './components/Service';
import Note from './components/Note';
import Shop from './components/Shop';
import UFUEvent from './components/UFUEvent';
import ExternalPage from './components/ExternalPage';
import PdfViewer from './components/PdfViewer';
import RegisterStep1 from './components/RegisterStep1';
import RegisterStep2 from './components/RegisterStep2';
import RegisterComplete from './components/RegisterComplete';

function App() {

  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/"                 element={<Page mobileNav={true} needLogin={false} contentId="dashboard" content={Dashboard} disableBack={1} />} />
          <Route exact path="/login"            element={<Page mobileNav={false} needLogin={false} contentId="login" content={Login} />} />
          <Route exact path="/logout"           element={<Page mobileNav={false} needLogin={false} contentId="logout" content={Logout} disableBack={1} />} />
          <Route exact path="/verify"           element={<Page mobileNav={false} needLogin={false} contentId="verify" content={Verify} disableBack={1} />} />
          <Route exact path="/register-step-1"  element={<Page mobileNav={false} needLogin={false} contentId="register" content={RegisterStep1} />} />
          <Route exact path="/register-step-2"  element={<Page mobileNav={false} needLogin={false} contentId="register" content={RegisterStep2} />} />
          <Route exact path="/register-complete"  element={<Page mobileNav={false} needLogin={false} contentId="register" content={RegisterComplete} />} />
          <Route exact path="/profile"          element={<Page mobileNav={true}  needLogin={true}  contentId="profile" content={Profile} />} />
          <Route exact path="/hotline"          element={<Page mobileNav={true}  needLogin={true}  contentId="hotline" content={Hotline} />} />
          <Route exact path="/alerts"           element={<Page mobileNav={true}  needLogin={false}  contentId="alert" content={Alert} />} />
          <Route exact path="/alert/:id"        element={<Page mobileNav={true}  needLogin={false}  contentId="single-alert" content={SingleAlert} />} />
          <Route exact path="/contact-us"       element={<Page mobileNav={true}  needLogin={true}  contentId="contact-form" content={ContactForm} />} />
          <Route exact path="/pay-rates"        element={<Page mobileNav={true}  needLogin={true}  contentId="pay-rates" content={PayRates} />} />
          <Route exact path="/newsletter"       element={<Page mobileNav={true}  needLogin={true}  contentId="newsletter" content={Newsletter} />} />
          <Route exact path="/what-we-do"       element={<Page mobileNav={true}  needLogin={false}  contentId="what-we-do" content={WhatWeDo} />} />
          <Route exact path="/document"         element={<Page mobileNav={true}  needLogin={true}  contentId="document" content={Document} />} />
          <Route exact path="/pd-resources"     element={<Page mobileNav={true}  needLogin={true}  contentId="pd-resources" content={PDResource} />} />
          <Route exact path="/seminar-and-workshop" element={<Page mobileNav={true}  needLogin={false}  contentId="seminar-and-workshop" content={Seminar} />} />
          <Route exact path="/resources"        element={<Page mobileNav={true}  needLogin={false}  contentId="resources" content={Resource} />} />
          <Route exact path="/links"            element={<Page mobileNav={true}  needLogin={false}  contentId="links" content={Links} />} />
          <Route       path="*"                 element={<Page mobileNav={true}  needLogin={true}  contentId="notfound" content={NotFound} />} />


          <Route exact path="/calendar"       element={<Page mobileNav={true}  needLogin={true}  contentId="calendar" content={Calendar} />} />
          <Route exact path="/bulletins"      element={<Page mobileNav={true}  needLogin={true}  contentId="bulletin" content={Bulletin} />} />
          <Route exact path="/contacts"       element={<Page mobileNav={true}  needLogin={true}  contentId="contact" content={Contact} />} />
          <Route exact path="/videos"         element={<Page mobileNav={true}  needLogin={true}  contentId="video" content={Video} />} />
          <Route exact path="/documents"      element={<Page mobileNav={true}  needLogin={true}  contentId="document" content={Document} />} />
          <Route exact path="/locations"      element={<Page mobileNav={true}  needLogin={true}  contentId="location" content={Location} />} />
          <Route exact path="/services"       element={<Page mobileNav={true}  needLogin={true}  contentId="service" content={Service} />} />
          <Route exact path="/shop"           element={<Page mobileNav={true}  needLogin={true}  contentId="shop" content={Shop} />} />
          <Route exact path="/events"         element={<Page mobileNav={true}  needLogin={true}  contentId="ufu-event" content={UFUEvent} />} />
          <Route exact path="/notes"          element={<Page mobileNav={true}  needLogin={true}  contentId="ufu-note" content={Note} />} />
          <Route exact path="/external"       element={<Page mobileNav={true}  needLogin={false}  contentId="external" content={ExternalPage} />} />
          <Route exact path="/pdf/:urlBase64" element={<Page mobileNav={true}  needLogin={false}  contentId="pdf" content={PdfViewer} />} />
        </Routes>
      </Router>
      <ToastContainer />  
      <Hook />
    </>
  );
}

export default App;
