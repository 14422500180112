import React, { useEffect, useState } from 'react';
// import Form from 'react-bootstrap/Form';
// import { ReactComponent as IconSearch } from '../icons/ico-search.svg';
import { ReactComponent as IconGear } from '../icons/ico-gear.svg';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { get_services } from '../helpers/api.service';
import { ReactComponent as Logo } from '../images/logo.svg';
import { Button } from 'react-bootstrap';
import { ReactComponent as IconPhone } from '../icons/ico-phone.svg';

const Hotline = (props) => {
    const [data, setData] = useState([
        {
            name: 'Office',
            phone: '(02) 61500 800',
        }
    ]);

    // useEffect(() => {
    //     get_services().then(res => {
    //         if(res.data.data.services!=null){        
    //             setData(res.data.data.services);
    //         }
    //     });
    // },[])

    return (
        <div className="row">
            <div className="col-12">
                <div className="page-logo">
                    <Logo />
                </div>
            </div>
            <div className="col-12">
                <h1 className="page-title extra-space">Phone Hotline</h1>

                <div className="transparent-wrap">
                    <h2 className="big-title mb-4">DJMIR Hotline</h2>
                    <p className="large-text">Click to call the DJMIR Hotline</p>
                    <p className="extra-large-text">02 61 500 800</p>
                    <a className="w-100" href="tel:0261500800"><Button className="position-relative f-width mt-5" variant="primary" style={{fontSize: '24px'}}><IconPhone style={{position: 'absolute', left: '15px', width: '27px', height: '27px', top: '11px'}} />Click to Call</Button></a>
                </div>
                {/* <div className="list-phones">
                    {data === null && <p className="text-center">Loading...</p>}
                    {data !== null && data.map((contact, index) => {
                        // console.log(service);
                        return(
                            <li key={index}>
                                {contact.name}: <a href={'tel:'+contact.phone}>{contact.phone}</a>
                            </li>
                        )
                    })}
                </div> */}
            </div>
        </div>
    )
}

export default Hotline;