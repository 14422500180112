
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
// import { Col } from 'react-bootstrap';
import { submitRegisterComplete, resetRegisterComplete } from '../slices/userSlice';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay-ts';

import { ReactComponent as Logo } from '../images/logo.svg';

const RegisterComplete = (props) => {

    const dispatch = useDispatch();
    const userState = useSelector(state => state.user);

    return (
        <div className="row">
            <div className="col-12">
                <div className="page-logo">
                    <Logo />
                </div>
            </div>
            <div className="col-12">
                <div className="center-screen text-center">
                    <h2 className="big-title">Registration Complete</h2>
                    <p className="large-text mb-5">Your registration has been sent to our admin staff for review.</p>
                    <p>Once we have reviewed your application, we will notify you by email.</p>
                    <Link className="w-100" to="/login"><Button className="f-width mt-5" variant="primary">Return to Login</Button></Link>
                </div>
            </div>
        </div>
    )
}

export default RegisterComplete;