import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
// import { Col } from 'react-bootstrap';
import { sendRateMail, getPayRates, getPayRatesPDF, getDownloadUrl, resetPayRate, resetDownload, setPayRatePDF, sendPDFMail } from '../slices/userSlice';
import LoadingOverlay from 'react-loading-overlay-ts';
import { toast } from 'react-toastify';
import '../css/payRates.css';

import { ReactComponent as Logo } from '../images/logo.svg';

import { ReactComponent as IconPDF } from '../icons/ico-pdf.svg';
import { ReactComponent as IconArrowRight } from '../icons/ico-right-arrow-2.svg';

const PayRates = (props) => {

    const dispatch = useDispatch();
    const userState = useSelector(state => state.user);

    const [formFields, setFormFields] = useState({
        role: '',
        level: '',
        role_category: '',
    });

    const [validated, setValidated] = useState(false);
    const [roleOptions, setRoleOptions] = useState([]);
    const [levelOptions, setLevelOptions] = useState([]);
    const [roleCategoryOptions, setRoleCategoryOptions] = useState([]);
    const [rates, setRates] = useState([]);

    const [isChanged, setIsChanged] = useState(false);

    const [checked, setChecked] = useState([]);

    const handleSubmit = (event) => {
        event.preventDefault();

        let params = formFields;

        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.stopPropagation();
        }

        setValidated(true);

        if (form.checkValidity() === true) {
            dispatch(sendPDFMail(params))
        }
    }

    const fieldChange = (e) => {

        setFormFields(formFields => ({
            ...formFields,
            [e.target.name]: e.target.value,
        }));

        setIsChanged(true);
    }

    const handleDownload = async (fileUrl) => {
        /* const response = await fetch(fileUrl, {
            method: 'GET',
            headers: {
                // Add any necessary headers here
            },
        });

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.zip'); // Specify the file name for the download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url); // Clean up URL.createObjectURL reference */

        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = 'pay-rates.zip'; // Specify the file name for the download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const downloadFile2 = async (file) => {
        try {
            const response = await fetch(file.link, {
                headers: {
                    'Content-Type': 'application/pdf'
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = getFileNameFromUrl(file.link);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    const downloadFile3 = (file) => {
        const link = document.createElement('a');
        // link.target = '_blank';
        link.href = file.link;
        link.download = getFileNameFromUrl(file.link);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const downloadFile = (file) => {
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = file.link;
        document.body.appendChild(iframe);
        setTimeout(() => document.body.removeChild(iframe), 1000);
      };

    const downloadAllFiles = (pdf) => {
        pdf.forEach((file) => {
            // console.log('download', file);
            downloadFile(file);
        });
    };

    const getFileNameFromUrl = (url) => {
        return url.substring(url.lastIndexOf('/') + 1);
    };

    useEffect(() => {
        getResult();
    }, [formFields])

    const getResult = () => {
        if (userState.pay_rate.data.length) {
            let rates = userState.pay_rate.data.filter((rate) => {
                if (rate['role'] === formFields.role &&
                    rate['level'] === formFields.level &&
                    rate['role_category'] === formFields.role_category) {
                    return rate
                }
            })
            setRates(rates);
        }
    }

    useEffect(() => {
        if (!userState.pay_rate.isLoaded)
            // dispatch(getPayRates());
            dispatch(getPayRatesPDF());
    }, [userState.pay_rate.isLoaded])

    useEffect(() => {
        // console.log('pay rates', userState.pay_rate.data)
        if (userState.pay_rate.data.length) {

            Array.prototype.contains = function (v) {
                for (var i = 0; i < this.length; i++) {
                    if (this[i] === v) return true;
                }
                return false;
            };

            Array.prototype.unique = function () {
                var arr = [];
                for (var i = 0; i < this.length; i++) {
                    if (!arr.contains(this[i])) {
                        arr.push(this[i]);
                    }
                }
                return arr;
            }

            setRoleOptions(userState.pay_rate.data.map((rate) => rate.role).unique());
            setLevelOptions(userState.pay_rate.data.map((rate) => rate['level']).unique());
            setRoleCategoryOptions(userState.pay_rate.data.map((rate) => rate['role_category']).unique());
        }
    }, [dispatch])

    useEffect(() => {
        if (userState.pay_rate.sent && !userState.pay_rate.isLoading) {
            dispatch(resetPayRate());
            toast.success('Pay Rates sent', {
                toastId: 'save-pay-rate-success',
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }, [userState.pay_rate.sent, userState.pay_rate.isLoading, dispatch])

    /* useEffect(() => {
        if (userState.pay_rate.downloaded && !userState.pay_rate.isLoading) {
            handleDownload(userState.pay_rate.downloaded);
            dispatch(resetDownload());
        }
    }, [userState.pay_rate.downloaded, userState.pay_rate.isLoading, dispatch]) */

    useEffect(() => {
        if (userState.pay_rate.errMsg) {
            dispatch(resetPayRate());
            toast.error(userState.pay_rate.errMsg, {
                toastId: 'save-pay-rate-error',
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    }, [userState.pay_rate.errMsg])

    const AUD = new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
    });

    return (
        <div className="row">
            <LoadingOverlay
                active={userState.pay_rate.isLoading}
                // active={true}
                // spinner={<BounceLoader />}
                text=""
            >
            </LoadingOverlay>
            <div className="col-12">
                <div className="page-logo">
                    <Logo />
                </div>
            </div>
            <div className="col-12">
                <h1 className="page-title extra-space">Pay Rates</h1>
                <div className="pay_rate-setting">
                    <Form noValidate validated={validated} className="form-pay_rate col-12" onSubmit={handleSubmit}>

                        {userState.pay_rate.isLoaded && userState.pay_rate.pdf.length > 0 && userState.pay_rate.pdf.map((pdf, i) => {

                            return <Form.Group className="form-group mb-3" controlId="level" key={i} >
                                <div className="w-icon">
                                    <div className="input-wrap">
                                        <IconPDF />
                                        <label>{pdf.title}</label>
                                        <Form.Check className="stick-right custom-checkbox">
                                            <Form.Check.Input type="checkbox" checked={pdf.checked} onChange={() => {

                                                dispatch(setPayRatePDF({ index: i, checked: !pdf.checked }));
                                            }} />
                                            <Form.Check.Label onClick={(event) => {

                                                dispatch(setPayRatePDF({ index: i, checked: !pdf.checked }));
                                            }}>
                                            </Form.Check.Label>
                                        </Form.Check>
                                    </div>
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    Please select level
                                </Form.Control.Feedback>
                            </Form.Group>
                        })}

                        <Button className="f-width mt-4" variant="border" onClick={() => {
                            // dispatch(getDownloadUrl());
                            let pdf = userState.pay_rate.pdf.map((pdf) => {
                                if (pdf.checked) {
                                    return pdf;
                                }
                            }).filter(q => !!q);

                            downloadAllFiles(pdf);
                        }} disabled={userState.pay_rate.pdf.filter(pdf => pdf.checked).length === 0}>DOWNLOAD</Button>

                        <Button variant="transparent" className="mt-3" type="submit" style={{ display: 'block', margin: '0 auto' }} disabled={userState.pay_rate.pdf.filter(pdf => pdf.checked).length === 0}>
                            Send by email <IconArrowRight />
                        </Button>

                    </Form>
                </div>
            </div>
        </div>
    )
}

export default PayRates;