import React from 'react';
import Iframe from 'react-iframe'
import { ReactComponent as Logo } from '../images/logo.svg';

const PDResource = (props) => {

    return (
        <div className="row">
            <div className="col-12">
                <div className="page-logo">
                    <Logo />
                </div>
            </div>
            <div className="col-12">
                <h1 className="page-title extra-space">PD Resources</h1>

                {/* <object type="text/html" data="https://www.djmir.com.au/Training-and-Professional-Development" width="100%" height="100%"></object> */}
                <Iframe 
                    url="https://www.djmir.com.au/Training-and-Professional-Development" 
                    width="100%" 
                    height="100%" 
                    // styles={{height: "100vh"}}
                />
            </div>
        </div>
    )
}

export default PDResource;