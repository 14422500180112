import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
// import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
// import '../css/register.css';
import { ReactComponent as Logo } from '../images/logo.svg';
import { ReactComponent as IconProvider } from '../icons/ico-provider.svg';
import { ReactComponent as IconCase } from '../icons/ico-case.svg';
import { ReactComponent as IconUser } from '../icons/ico-user-3.svg';
import { ReactComponent as IconPhone } from '../icons/ico-phone.svg';
import { ReactComponent as IconMessage } from '../icons/ico-message.svg';
import {
    registUser,
    resetRegister,
} from '../slices/userSlice';
import { Navigate } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay-ts';
// import BounceLoader from 'react-spinners/BounceLoader';
// import { resetRegisterError } from '../slices/userSlice';

const RegisterStep2 = (props) => {

    const userState = useSelector(state => state.user);

    const dispatch = useDispatch();

    console.log('userData', userState.register.userData)

    const [formFields, setFormFields] = useState({
        first_name: userState.register.userData.first_name,
        last_name: userState.register.userData.last_name,
        email: userState.register.userData.email,
        phone: userState.register.userData.phone,
        provider_number: userState.register.userData.provider_number,
        service_number: userState.register.userData.service_number,
        number_of_services: userState.register.userData.number_of_services,
    });

    const [validated, setValidated] = useState(false);

    const service_options = [
        '1 Service – $181.84 per month',
        '2 Services – $214.91 per month',
        '3 Services – $247.99 per month',
        '4 Services – $281.06 per month',
        '5 Services – $314.13 per month',
        '6 Services – $314.13 per month',
        '7 Services – $380.28 per month',
        '8 Services – $413.35 per month',
        '9 Services – $446.42 per month',
        '10 Services or more – $479.50 per month',
    ]

    const fieldChange = (e) => {

        setFormFields(formFields => ({
            ...formFields,
            [e.target.name]: e.target.value,
        }));
    }

    const handleRegister = (event) => {

        event.preventDefault();

        if (!userState.isRegisterLoading) {

            const form = event.currentTarget;

            if (form.checkValidity() === false) {
                event.stopPropagation();
            }

            setValidated(true);

            if (form.checkValidity() === true) {
                dispatch(registUser({
                    first_name: formFields.first_name,
                    last_name: formFields.last_name,
                    phone: formFields.phone,
                    email: formFields.email,
                    provider_number: formFields.provider_number,
                    service_number: formFields.service_number,
                    number_of_services: formFields.number_of_services,
                }));
            }
        }
    }

    useEffect(() => {

        if (userState.register.registered) {
            dispatch(resetRegister());
        }

    }, [userState.register.isLoaded])

    if (userState.isLoggedIn) {
        return <Navigate to="/" />
    }

    if (!formFields.provider_number) {
        return <Navigate to="/register-step-1" />
    }

    if (userState.register.registered) {
        return <Navigate to="/register-complete" />
    }

    return (
        <div className="register-wrap row">
            <LoadingOverlay
                active={userState.register.isLoading}
                // active={true}
                // spinner={<BounceLoader />}
                text=""
            >
            </LoadingOverlay>
            <div className="col-12">
                <div className="page-logo">
                    <Logo />
                </div>
            </div>
            <div className="col-12">
                {/* <h1 className="page-title extra-space">Register</h1> */}

                <div className="form-wrap center-screen">
                    <Form noValidate validated={validated} className="form-register col-12" onSubmit={handleRegister}>
                        <Form.Group className="form-group mb-3_5 w-icon" controlId="first-name">
                            <IconUser />
                            <Form.Control type="text" name="first_name" placeholder="First name" value={formFields.first_name} required={true} onChange={(e) => fieldChange(e)} />
                            <Form.Control.Feedback type="invalid">
                                Please enter valid first name
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-group mb-3_5 w-icon" controlId="last-name">
                            <IconUser />
                            <Form.Control type="text" name="last_name" placeholder="Last name" value={formFields.last_name} required={true} onChange={(e) => fieldChange(e)} />
                            <Form.Control.Feedback type="invalid">
                                Please enter valid last name
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-group mb-3_5 w-icon" controlId="phone">
                            <IconPhone />
                            <Form.Control type="text" name="phone" placeholder="Phone" value={formFields.phone} required={true} onChange={(e) => fieldChange(e)} />
                            <Form.Control.Feedback type="invalid">
                                Please enter valid phone number
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-group mb-3_5 w-icon" controlId="email">
                            <IconMessage />
                            <Form.Control type="email" name="email" placeholder="Email" value={formFields.email} required={true} onChange={(e) => fieldChange(e)} />
                            <Form.Control.Feedback type="invalid">
                                Please enter valid email
                            </Form.Control.Feedback>
                        </Form.Group>


                        <Form.Group className="form-group mb-3_5 w-icon" controlId="provider-number">
                            <IconProvider />
                            <Form.Control type="text" name="provider_number" placeholder="Provider number" value={formFields.provider_number} required={true} readOnly={true} onChange={(e) => fieldChange(e)} />
                            <Form.Control.Feedback type="invalid">
                                Please enter valid provider number
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-group mb-3_5 w-icon" controlId="service-number">
                            <IconCase />
                            {/* <Form.Control type="service_number" name="service_number" placeholder="Service number" value={formFields.service_number} required={true} onChange={(e) => fieldChange(e)} /> */}
                            <Form.Select name="service_number" required={false} value={formFields.service_number} onChange={(e) => fieldChange(e)}>
                                <option value="">Service number</option>
                                {userState.register.serviceNumbers.map((option, i) => {
                                    return <option key={i} value={option}>{option}</option>
                                })}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                Please select service number
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-group mb-3_5 w-icon" controlId="number-of-services">
                            <IconCase />
                            <Form.Select name="number_of_services" required={true} value={formFields.number_of_services} onChange={(e) => fieldChange(e)}>
                                <option value="">Number of services</option>
                                {service_options.map((option) =>
                                    <option value={option}>{option}</option>)}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                Please select number of services
                            </Form.Control.Feedback>
                            {/* {formFields.number_of_services && <div className="field-info">Number of services: <strong>{service_options.indexOf(formFields.number_of_services) + 1}</strong></div>} */}
                            <div className="field-info">Number of services: <strong>{userState.register.serviceNumbers ? userState.register.serviceNumbers.length : 0}</strong></div>
                        </Form.Group>

                        {userState.register.errMsg && <Alert className="mt-3" variant="danger">{userState.register.errMsg}</Alert>}

                        <Button className="btn-register f-width btn-w-icon" variant="primary" type="submit">
                            Next
                            {/* <IconArrow className="svg-ico" /> */}
                        </Button>
                    </Form>
                </div>
            </div>
        </div>
    );
}

export default RegisterStep2;