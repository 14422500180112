import React, { useState } from 'react';
import { Navigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as IconCircle } from '../icons/ico-circle.svg';
import { ReactComponent as IconCheck } from '../icons/ico-check.svg';
import { getNotifications, localNotificationSeen, notificationSeen } from '../slices/userSlice';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { ReactComponent as Logo } from '../images/logo.svg';
import { ReactComponent as IconBell } from '../icons/ico-bell.svg';
import { ReactComponent as IconBell2 } from '../icons/ico-bell-2.svg';
import { getLocalNotificationSeens } from '../helpers/generalFunctions';

const Notification = (props) => {

    const userState = useSelector(state => state.user);
    const [id, setId] = useState(0);
    const dispatch = useDispatch();

    if (id) {
        return <Navigate to={`/alert/${id.toString()}`} />
    }

    const notification_seens = getLocalNotificationSeens();

    return (
        <div className="row">
            <div className="col-12">
                <div className="page-logo">
                    <Logo />
                </div>
            </div>
            <div className="col-12">
                <h1 className="page-title extra-space">Alerts</h1>

                {/* <div className="btn-action-wrap">
                    <Form.Group className="mt-3">
                        <Button className="btn-clear-notification" variant="secondary" onClick={() => {
                            dispatch(notificationSeen({for:'all', type:'alert'})).then(() => {
                                dispatch(getNotifications());
                            });
                        }}><IconCheck /> Mark all as read</Button>
                    </Form.Group>
                </div> */}

                <div className="item-list list-alert">
                    {userState.notification.data !== null && userState.notification.data.map((notif, index) => {
                        return (<li key={index} onClick={() => {
                            if ((notif.seen !== 1) && userState.isLoggedIn) {

                                setId(notif.id);
                                dispatch(notificationSeen({ id: notif.id, type: 'alert' }));
                            } else {
                                setTimeout(() => {

                                    dispatch(localNotificationSeen(notif.id));
                                }
                                    , 1);
                                setId(notif.id)
                            }
                        }}>
                            {/* <div className={(notif.seen === "0" ? 'highlight ' : '' ) + 'item-header' }><span className={(notif.seen === "0" ? 'highlight ' : '') + 'item-icon' }><IconCircle /></span><span className="sender">{notif.sender}</span><span className="post-time">{notif.time}</span></div>
                        <div className="item-desc highlight">{notif.title}</div> */}

                            <div className="list-wrap row">
                                <div className="col-9">
                                    <div className={'list-icon' + ((userState.isLoggedIn && notif.seen === 0) || (!userState.isLoggedIn && !notification_seens.includes(parseInt(notif.id))) ? ' highlight' : '')}>
                                        {(userState.isLoggedIn && notif.seen === 0) || (!userState.isLoggedIn && !notification_seens.includes(parseInt(notif.id))) ? <IconBell /> : <IconBell2 />}
                                    </div>
                                    <div className="list-title">{notif.title}</div>
                                </div>
                                <div className="col-3">
                                    <span className="post-time">{notif.time}</span>
                                </div>
                            </div>
                        </li>)
                    })}
                    {userState.notification.data === null || userState.notification.data !== null && userState.notification.data.length === 0 && <p className="text-center">No alert</p>}
                    {userState.notification.errorMsg !== null && <Alert className="mt-3" variant="warning">{userState.notification.errorMsg}</Alert>}
                </div>
            </div>
        </div>
    )
}

export default Notification;