import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useParams, useSearchParams, Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../images/logo.svg';
import {
    sso,
} from '../slices/userSlice';
import { Navigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { WA_REDIRECT_URI } from '../helpers/constants';

const Verify = (props) => {

    const userState = useSelector(state => state.user);
    // const {code} = useParams();
    const [queryParameters] = useSearchParams()

    const dispatch = useDispatch();

    useEffect(() => {
        if(! userState.loginErrorMsg && ! userState.isLoggedIn && !userState.isLoginLoading){
            console.log('code', queryParameters.get("code"));
            dispatch(sso({
                code: queryParameters.get("code"),
                redirect_uri: WA_REDIRECT_URI,
            }));
        }
    }, [])

    if (userState.isLoginLoading) {
        return (
            <LoadingOverlay
                active={true}
                // spinner={<BounceLoader />}
                text=""
            >
            </LoadingOverlay>
        );
    }

    if (userState.isLoggedIn) {
        return <Navigate to="/" />
    }

    // return (
    //     <div className="row">
    //         <div className="col-12">
    //             <div className="page-logo">
    //                 <Logo />
    //             </div>
    //         </div>
    //         <div className="col-12">
    //             <div className="center-screen text-center">
    //                 <h2 className="big-title">Login Success</h2>
    //                 <p className="large-text mb-5">Please continue</p>
    //                 <Link className="w-100" to="/"><Button className="f-width mt-5" variant="primary">Return to Dashboard</Button></Link>
    //             </div>
    //         </div>
    //     </div>
    // )
    return (
        <div className="row">
            <div className="col-12">
                <div className="page-logo">
                    <Logo />
                </div>
            </div>
            <div className="col-12">
                <div className="center-screen text-center">
                {userState.loginErrorMsg && <Alert className="mt-3" variant="danger">{userState.loginErrorMsg}</Alert>}
                </div>
            </div>
        </div>
    )
}

export default Verify;