import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
// import { Col } from 'react-bootstrap';
import { default as ReactSelect } from "react-select";
import Option from '../helpers/option';
import { saveProfile, resetProfileSaved, googleSignIn, googleSignOut, getSyncStatus, googleSync, getProfile, resetSyncError, removeGoogleEvents, resetMediaRemoved, addEndpoint } from '../slices/userSlice';
import LoadingOverlay from 'react-loading-overlay-ts';
import { toast } from 'react-toastify';
import '../css/profile.css';
import { getFieldValue, isProfileComplete, profileCompletionReminder } from '../helpers/generalFunctions';
import { userLogout, setNextReminder } from '../slices/userSlice';
import Alert from 'react-bootstrap/Alert';
import { confirmAlert } from 'react-confirm-alert';
import { PUSH_NOTIFICATION_PUBLIC_KEY as publicKey } from '../helpers/constants';
import { getMobileOS, PWACanInstall, PWAIsInstalled } from '../helpers/browser';
import { Navigate } from "react-router-dom";

import { ReactComponent as Logo } from '../images/logo.svg';

import { ReactComponent as IconBell } from '../icons/ico-bell.svg';
import { ReactComponent as IconUser } from '../icons/ico-user-3.svg';
import { ReactComponent as IconProvider } from '../icons/ico-provider.svg';
import { ReactComponent as IconCase } from '../icons/ico-case.svg';
import { ReactComponent as IconPhone } from '../icons/ico-phone.svg';
import { ReactComponent as IconMessage } from '../icons/ico-message.svg';

const Profile = (props) => {

    const dispatch = useDispatch();
    const userState = useSelector(state => state.user);

    // console.log('user',userState.user);

    const [userFields, setUserFields] = useState({
        firstName: userState.user.FirstName,
        lastName: userState.user.LastName,
        phone: getFieldValue(userState.user, 'Contact phone'),
        email: userState.user.Email,
        providerNumber: getFieldValue(userState.user, 'Provider number'),
        providerName: getFieldValue(userState.user, 'Provider name'),
        providerContactName: getFieldValue(userState.user, 'Provider 1st contact name'),
        serviceNumber: getFieldValue(userState.user, 'Service number'),
        serviceName: getFieldValue(userState.user, 'Service Name'),
        serviceAddress: getFieldValue(userState.user, 'Service postal address'),
        serviceSuburb: getFieldValue(userState.user, 'Service town/suburb'),
        serviceState: getFieldValue(userState.user, 'Service state/territory'),
        servicePostcode: getFieldValue(userState.user, 'Service postcode'),
        // serviceContactName: getFieldValue(userState.user, 'Provider Contact Name'),
        // serviceContactName: getFieldValue(userState.user, 'Provider Contact Name'),
    });

    const [logout, setLogout] = useState(false);

    const [iOSNotification, setIOSNotification] = useState(localStorage.getItem("iOSNotification") === "true" ? true : false);

    const [validated, setValidated] = useState(false);

    const [pwaBtnEnabled, setPwaBtnEnabled] = useState(localStorage.getItem("pushnotification") !== null);

    const [sw, setSw] = useState(null);

    // console.log('pushnotification value', localStorage.getItem("pushnotification"));
    // console.log('pushnotification', localStorage.getItem("pushnotification")!==null);
    // const [profileReminder, setProfileReminder] = useState(true);

    const doLogout = () => {
        // dispatch(userLogout());
        setLogout(true);
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        let params = userFields;

        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.stopPropagation();
        }

        setValidated(true);

        if (form.checkValidity() === true) {
            dispatch(saveProfile(params))
        }
    }

    const ProfileReminder = () => {
        if (!isProfileComplete(userState.user) && profileCompletionReminder(userState.user)) {
            console.log(userState.user);
            if (userState.user.profileReminderCount === 0) {
                return <Alert className="" variant="warning">Please complete your profile <a href="#" className="btn-close" onClick={() => { dispatch(setNextReminder()); }}></a></Alert>;
            } else {
                return <Alert className="" variant="warning">Please confirm that your profile details are correct <a href="#" className="btn-close" onClick={() => { dispatch(setNextReminder()); }}></a></Alert>;
            }
        }

        return <></>;
    }

    const fieldChange = (e) => {
        // let userFieldsTemp = userFields;

        // console.log(e.target.name, e.target.value);
        // console.log('before', userFieldsTemp);
        // userFieldsTemp[e.target.name] = e.target.value;

        // console.log('after', userFieldsTemp);

        setUserFields(userFields => ({
            ...userFields,
            [e.target.name]: e.target.value,
        }));
    }

    useEffect(() => {

        const getServiceWorker = async () => {
            let sw = await navigator.serviceWorker.getRegistration();
            setSw(sw);
        };

        getServiceWorker().catch(console.error);
    }, []);

    // useEffect(() => {
    //     console.log('userFields', userFields)
    // },[])

    useEffect(() => {
        if (!userState.profile.isLoaded)
            dispatch(getProfile());
    }, [userState.profile.isLoaded])

    useEffect(() => {
        if (userState.profile.saved && !userState.profile.isLoading) {
            dispatch(resetProfileSaved());
            toast.success('Profile Saved', {
                toastId: 'save-profile-success',
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }, [userState.profile.saved, userState.profile.isLoading, dispatch])

    useEffect(() => {
        if (userState.profile.saveErrMsg) {
            dispatch(resetProfileSaved());
            toast.error(userState.profile.saveErrMsg, {
                toastId: 'save-profile-error',
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    }, [userState.profile.saveErrMsg])

    useEffect(() => {
        if (userState.notification.enable == true) {
            setPwaBtnEnabled(true);
        }
    }, [userState.notification.enable])

    if(logout){
        return <Navigate to="/logout" />
    }

    return (
        <div className="row">
            <LoadingOverlay
                active={userState.profile.isLoading || userState.profile.mediaRemoving}
                // active={true}
                // spinner={<BounceLoader />}
                text=""
            >
            </LoadingOverlay>
            <div className="col-12">
                <div className="page-logo">
                    <Logo />
                </div>
            </div>
            <div className="col-12">
                <h1 className="page-title extra-space">Profile</h1>
                <div className="alert-wrap">
                    {/* <ProfileReminder /> */}
                </div>
                <div className="profile-setting">
                    <Form noValidate validated={validated} className="form-profile col-12" onSubmit={handleSubmit}>
                        <div className="row">
                            <Form.Group className="form-group mb-3_5 col-6" controlId="firstName">
                                <div className="w-icon">
                                    <IconUser />
                                    <Form.Control type="text" name="firstName" placeholder="First name*" required={true} value={userFields.firstName} onChange={(e) => fieldChange(e)} />
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    Please enter first name
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="form-group mb-2 col-6" controlId="lastName">
                                <div className="w-icon">
                                    <IconUser />
                                    <Form.Control type="text" name="lastName" placeholder="Last name*" required={true} value={userFields.lastName} onChange={(e) => fieldChange(e)} />
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    Please enter last name
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>

                        <Form.Group className="form-group mb-3_5" controlId="phone">
                            <div className="w-icon">
                                <IconPhone />
                                <Form.Control type="text" name="phone" placeholder="Phone" required={false} value={userFields.phone} onChange={(e) => fieldChange(e)} />
                            </div>
                            <Form.Control.Feedback type="invalid">
                                Please enter mobile phone
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-group mb-2" controlId="email">
                            <div className="w-icon">
                                <IconMessage />
                                <Form.Control type="text" name="email" placeholder="Email" required={false} value={userFields.email} onChange={(e) => fieldChange(e)} readOnly={true} />
                            </div>
                            <Form.Control.Feedback type="invalid">
                                Please enter email
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-group mb-2" controlId="provider-number">
                            <Form.Text muted>
                                Provider number
                            </Form.Text>
                            <div className="w-icon">
                                <IconProvider />
                                <Form.Control type="text" name="providerNumber" placeholder="Provider number" required={false} value={userFields.providerNumber} onChange={(e) => fieldChange(e)} readOnly={true} />
                            </div>
                            <Form.Control.Feedback type="invalid">
                                Please enter provider name
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-group mb-2" controlId="provider-name">
                            <Form.Text muted>
                                Provider name
                            </Form.Text>
                            <div className="w-icon">
                            <IconProvider />
                                <Form.Control type="text" name="providerName" placeholder="Provider name" required={false} value={userFields.providerName} onChange={(e) => fieldChange(e)} />
                            </div>
                            <Form.Control.Feedback type="invalid">
                                Please enter provider name
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-group mb-2" controlId="provider-contact-name">
                            <Form.Text muted>
                                Provider contact name
                            </Form.Text>
                            <div className="w-icon">
                            <IconProvider />
                                <Form.Control type="text" name="providerContactName" placeholder="Provider contact name" required={false} value={userFields.providerContactName} onChange={(e) => fieldChange(e)} />
                            </div>
                            <Form.Control.Feedback type="invalid">
                                Please enter provider contact name
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-group mb-2" controlId="service-number">
                            <Form.Text muted>
                                Service number
                            </Form.Text>
                            <div className="w-icon">
                            <IconCase />
                                <Form.Control type="text" name="serviceNumber" placeholder="Service number" required={false} value={userFields.serviceNumber} onChange={(e) => fieldChange(e)} readOnly={true} />
                            </div>
                            <Form.Control.Feedback type="invalid">
                                Please enter service number
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-group mb-2" controlId="service-name">
                            <Form.Text muted>
                                Service name
                            </Form.Text>
                            <div className="w-icon">
                            <IconCase />
                                <Form.Control type="text" name="serviceName" placeholder="Service name" required={false} value={userFields.serviceName} onChange={(e) => fieldChange(e)} />
                            </div>
                            <Form.Control.Feedback type="invalid">
                                Please enter service name
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-group mb-2" controlId="service-address">
                            <Form.Text muted>
                                Service address
                            </Form.Text>
                            <div className="w-icon">
                            <IconCase />
                                <Form.Control type="text" name="serviceAddress" placeholder="Service address" required={false} value={userFields.serviceAddress} onChange={(e) => fieldChange(e)} />
                            </div>
                            <Form.Control.Feedback type="invalid">
                                Please enter service address
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-group mb-2" controlId="service-suburb">
                            <Form.Text muted>
                                Service town/suburb
                            </Form.Text>
                            <div className="w-icon">
                            <IconCase />
                                <Form.Control type="text" name="serviceSuburb" placeholder="Service town/suburb" required={false} value={userFields.serviceSuburb} onChange={(e) => fieldChange(e)} />
                            </div>
                            <Form.Control.Feedback type="invalid">
                                Please enter service town/suburb
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-group mb-2" controlId="service-state">
                            <Form.Text muted>
                                Service state
                            </Form.Text>
                            <div className="w-icon">
                            <IconCase />
                                <Form.Control type="text" name="serviceState" placeholder="Service state" required={false} value={userFields.serviceState} onChange={(e) => fieldChange(e)} />
                            </div>
                            <Form.Control.Feedback type="invalid">
                                Please enter service state
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-group mb-2" controlId="service-postcode">
                            <Form.Text muted>
                                Service postcode
                            </Form.Text>
                            <div className="w-icon">
                            <IconCase />
                                <Form.Control type="text" name="servicePostcode" placeholder="Service postcode" required={false} value={userFields.servicePostcode} onChange={(e) => fieldChange(e)} />
                            </div>
                            <Form.Control.Feedback type="invalid">
                                Please enter service postcode
                            </Form.Control.Feedback>
                        </Form.Group>

                        {getMobileOS() === 'iOS' && PWACanInstall && PWAIsInstalled && typeof sw !== 'undefined' && sw !== null &&
                            <Form.Group className="mt-5">
                                <Button variant={pwaBtnEnabled ? 'green' : 'secondary'} className={'w-100'} disabled={pwaBtnEnabled ? true : false} onClick={async () => {


                                    // console.log(sw);
                                    await sw.pushManager.getSubscription().then((subscription) => {
                                        return sw.pushManager.subscribe({
                                            userVisibleOnly: true,
                                            applicationServerKey: publicKey
                                        }).then((subscribe) => {
                                            let json = JSON.stringify(subscribe);

                                            dispatch(addEndpoint({ json }));
                                            localStorage.setItem("pushnotification", json);
                                            console.log('pushNotification', json);
                                        });
                                    })

                                }}><IconBell /> iOS Notification</Button>
                                <div>
                                    <Form.Text muted className="col-12">
                                        Enable iOS push notification for 'Home Screen' mode only
                                    </Form.Text>
                                </div>
                            </Form.Group>}

                        <Button variant="primary" className="mt-5" type="submit">
                            Save Profile
                        </Button>
                        <button className="btn mt-3 btn-secondary f-width ms-auto me-auto d-block" onClick={(event) => { doLogout(); event.preventDefault() }}>Logout</button>

                    </Form>
                </div>
                {/* <div className="text-center">
                    {userState.user && userState.user.FirstName && <div className="profile-fullname">{userState.user.FirstName + ' ' + userState.user.LastName}</div>}
                    {userState.user && userState.user.Email && <div className="profile-email">{userState.user.Email}</div>}
                    <button className="btn btn-secondary mt-3" onClick={()=> logout() }>Logout</button>
                </div> */}
            </div>
        </div>
    )
}

export default Profile;