import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay-ts';
import {
    userLogout,
} from '../slices/userSlice';
import { Navigate } from 'react-router-dom';

const Logout = (props) => {

    const userState = useSelector(state => state.user);

    const dispatch = useDispatch();

    useEffect(() => {
        if (userState.isLoggedIn) {
            dispatch(userLogout());
        }
    }, [])

    if (userState.isLoggedIn) {
        return (
            <LoadingOverlay
                active={true}
                // spinner={<BounceLoader />}
                text=""
            >
            </LoadingOverlay>
        );
    }

    if (!userState.isLoggedIn) {
        return <Navigate to="/" />
    }
}

export default Logout;