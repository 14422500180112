import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import { useParams } from "react-router-dom";
import '../css/alert.css';
import { ReactComponent as IconNext } from '../icons/ico-next.svg';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import Iframe from 'react-iframe';
import LazyLoad from 'react-lazy-load';

const SingleAlert = (props) => {
    const [isPlay, setPlay] = useState(false);
    const userState = useSelector(state => state.user);
    let { id } = useParams();
    const alerts = userState.notification.data;
    const notification = alerts !== null ? alerts.find((alert) => {
        if( alert.id === id )
            return alert;

        return 0;
    }) : null;

    function get_url_extension( url ) {
        return url.split(/[#?]/)[0].split('.').pop().trim();
    }

    const dateFormat = (date) =>{

        var options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
        var today  = new Date(date);

        return today.toLocaleDateString("en-US", options);
    }

    const view_link = (link, data) => {
        let ext = get_url_extension(notification.link);
        switch(ext){
            case "pdf":
                    return <Link to={'/pdf/' + btoa(notification.link)}>View <IconNext /></Link>;
            default:

                    if(link.includes("youtube.com") || link.includes("vimeo.com")){

                        let video = typeof data.video !== 'undefined' ? data.video : null;

                        if( video == null ){
                            return <div className="single-video">
                                        <LazyLoad>
                                        <div>
                                            <div className="video">
                                                <Iframe 
                                                    url={data.link} 
                                                    width="100%" 
                                                    height="100%" 
                                                    // styles={{height: "100vh"}}
                                                />                                                
                                            </div>
                                        </div>
                                        </LazyLoad>
                                    </div>;
                        } else{
                        
                            return <div className="single-video">
                                        <LazyLoad>
                                        <div>
                                            <div className="video">
                                                {isPlay== false && <img 
                                                    // className={}
                                                    onClick={() => { 
                                                        setPlay(true);
                                                    }}
                                                    src={video.thumb === '' ? require('../images/play-button.webp') : video.thumb} 
                                                    alt={video.title} 
                                                    title={video.title} />}
                                                {isPlay === true &&
                                                    <Iframe 
                                                        url={video.url} 
                                                        width="100%" 
                                                        height="100%" 
                                                        // styles={{height: "100vh"}}
                                                    />}
                                                {/* <div style="padding:56.25% 0 0 0;position:relative;"><iframe src={video.player_embed_url + "?h=8f88230f7c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title={video.name}></iframe></div><script src="https://player.vimeo.com/api/player.js"></script> */}
                                            </div>
                                            <div className="video-info">
                                                <img src={require('../images/video-user.png')} alt="djmir" />
                                                <div className="video-desc">
                                                    <h3 className="video-title">{video.title}</h3>
                                                    <p className="video-meta"><span className="publish-date">{dateFormat(video.date)}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                        </LazyLoad>
                                    </div>;
                        }
                    }else{
                        return <a href={notification.link}>View <IconNext /></a>;
                    }
                break;
        }
    }

    return (
        <>
            {notification !== null ?
            <div className="row">           
                <div className="col-12">
                    <h1 className="page-title">{notification.title}</h1>
                    <p className="page-time">{notification.timeFormatted} | {notification.dateFormatted}</p>

                    <div className="alert-detail">
                        {notification.img && <img className="alert-img" src={notification.img} title={notification.title} alt={notification.title} />}

                        {parse(notification.content)}
                    </div>
                    {notification.link && <span className="alert-link">
                        {view_link(notification.link, notification)}
                    </span>}
                </div>
            </div>
            : null}
        </>
    )
}

export default SingleAlert;