import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Popup from 'reactjs-popup';
import Alert from 'react-bootstrap/Alert';
// import DatePicker from "react-datepicker";
import { Input, TextField } from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import "react-datepicker/dist/react-datepicker.css";
import 'reactjs-popup/dist/index.css';
// import TimePicker from 'react-time-picker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import enLocale from 'date-fns/locale/en-US';
// import frLocale from 'date-fns/locale/fr';
import auLocale from 'date-fns/locale/en-AU';
import { CalendarPicker, LocalizationProvider, bgBG } from '@mui/x-date-pickers';
import { ReactComponent as IconPlus } from '../icons/ico-plus.svg';
import { ReactComponent as IconClock } from '../icons/ico-clock.svg';
import { ReactComponent as IconDate } from '../icons/ico-date.svg';
import { ReactComponent as IconApple } from '../icons/ico-apple.svg';
import { ReactComponent as IconOutlook } from '../icons/ico-outlook.svg';
import { ReactComponent as IconPhoto } from '../icons/ico-photo.svg';
import { ReactComponent as IconVideo } from '../icons/ico-video.svg';
import { ReactComponent as IconVoice } from '../icons/ico-voice.svg';
import { ReactComponent as IconCamera } from '../icons/ico-camera.svg';
import { ReactComponent as IconAttachment } from '../icons/ico-attachment.svg';
import { ReactComponent as IconIcs } from '../icons/ico-ics.svg';
import { ReactComponent as IconClose } from '../icons/ico-close.svg';
import '../css/addEvent.css';
import {
    addEvent, resetCalendarLoaded, setFields,
} from '../slices/userSlice';
import LoadingOverlay from 'react-loading-overlay-ts';
import BounceLoader from 'react-spinners/BounceLoader';
import { toast } from 'react-toastify';
import { resetEventSubmit, getEvents, setActiveDate, setDayEvents, setDayLegends, setAddEvent } from '../slices/userSlice';
import { formatTime, formatDate } from '../helpers/time';
import { downloadIcs } from '../helpers/ics';
import VideoThumbnail from 'react-video-thumbnail';
import BrowserCamera from './BrowserCamera';
// import VoiceRecorder from './VoiceRecorder';
// import VoiceRecorder2 from './VoiceRecorder2';
// import VoiceRecorder3 from './VoiceRecorder3';
// import AudioRecorder from './AudioRecorder';
import { isBase64 } from '../helpers/generalFunctions';

const AddEvent = (props) => {

    const userState = useSelector(state => state.user);

    const dispatch = useDispatch();

    const [catOpen, setCatOpen] = useState(0);
    const [eventType, setEventType] = useState(userState.event.fields.type);
    const [validated, setValidated] = useState(false);
    const [eventName, setEventName] = useState('');
    const [eventNote, setEventNote] = useState('');
    const [eventDate, setEventDate] = useState(null);
    const [eventEndDate, setEventEndDate] = useState(null);
    const [eventShift, setEventShift] = useState('day');
    const [eventStartTime, setEventStartTime] = useState(null);
    const [eventEndTime, setEventEndTime] = useState(null);
    const [eventRecurring, setEventRecurring] = useState(0);
    const [recurringEndDate, setRecurringEndDate] = useState(null);
    const [recurrenceOpen, setRecurrenceOpen] = useState(0);
    const [repeatNumber, setRepeatNumber] = useState('');
    const [repeatPeriod, setRepeatPeriod] = useState('day');
    const [endType, setEndType] = useState('never');
    const [occurencesLimit, setOccurencesLimit] = useState('');
    const [monday, setMonday] = useState(false);
    const [tuesday, setTuesday] = useState(false);
    const [wednesday, setWednesday] = useState(false);
    const [thursday, setThursday] = useState(false);
    const [friday, setFriday] = useState(false);
    const [saturday, setSaturday] = useState(false);
    const [sunday, setSunday] = useState(false);

    const [cameraOpen, setCameraOpen] = useState(false);
    const [voiceOpen, setVoiceOpen] = useState(false);
    const [fileStorage, setFileStorage] = useState([]);
    const uploadPhotoRef = useRef(null);
    const uploadVideoRef = useRef(null);

    const openCategorySelection = () => {
        setCatOpen(!catOpen);
        dispatch(resetEventSubmit());
    }

    const openAddEventPopup = (type) => {
        // setEventType(type);
        dispatch(setAddEvent(type));
        setCatOpen(0);
    }

    const closeAddPopup = () => {
        setEventType('');
        setValidated(false);
        setEventName('');
        setEventNote('');
        setEventDate(null);
        setEventStartTime(null);
        setEventEndTime(null);
        setEventRecurring(0);
        setRecurringEndDate(null);
        setRecurrenceOpen(0);
        setRepeatNumber(null);
        setRepeatPeriod('week');
        setEndType('never');
        setOccurencesLimit('');
        setMonday(false);
        setTuesday(false);
        setWednesday(false);
        setThursday(false);
        setFriday(false);
        setSaturday(false);
        setSunday(false);

        dispatch(resetEventSubmit());
    }

    const openRecurringPopup = (checked) => {
        setRecurrenceOpen(checked);
        setEventRecurring(checked ? 1 : 0);
    }

    const closeRecurringPopup = () => {
        setRecurrenceOpen(false);
        setEventRecurring(0);
    }

    const saveRecurring = () => {
        setRecurrenceOpen(false);        
        setEventRecurring(1);
    }

    const getParams = () => {

        let params = {
            type: eventType,
        };
        
        if( eventName ) params.event_name = eventName;
        if( eventNote ) params.note = eventNote;
        if( eventDate ) params.date = formatDate(eventDate);
        if( eventEndDate ) params.end_date = formatDate(eventEndDate);
        if( eventShift ) params.shift = eventShift;
        if( eventStartTime ) params.start_time = formatTime(eventStartTime);
        if( eventEndTime ) params.end_time = formatTime(eventEndTime);
        if( eventRecurring ) params.is_recurring = eventRecurring;
        if( recurringEndDate ) params.end_date = formatDate(recurringEndDate);
        if( repeatNumber ) params.repeat_number = repeatNumber;
        if( repeatPeriod ) params.period = repeatPeriod;
        if( endType ) params.end_type = endType;
        if( occurencesLimit ) params.occurences = occurencesLimit;
        if( monday || tuesday || wednesday || thursday || friday || saturday || sunday ){
            params.repeat_on = [];            
            if(sunday) params.repeat_on.push(0);
            if(monday) params.repeat_on.push(1);
            if(tuesday) params.repeat_on.push(2);
            if(wednesday) params.repeat_on.push(3);
            if(thursday) params.repeat_on.push(4);
            if(friday) params.repeat_on.push(5);
            if(saturday) params.repeat_on.push(6);
        }

        if (fileStorage) {
            fileStorage.map((file) => {
                if(file.type.match("image")){
                    if( typeof params.photos === 'undefined' ){
                        params.photos = [];
                    }
                    params.photos.push(file);
                }else if(file.type.match("video")){
                    if( typeof params.videos === 'undefined' ){
                        params.videos = [];
                    }
                    params.videos.push(file);
                }else if(file.type.match("audio")){
                    if( typeof params.voices === 'undefined' ){
                        params.voices = [];
                    }
                    params.voices.push(file);
                }
            });
        }
        return params;
    }

    const handleSubmit = (event) => {

        event.preventDefault();

        let params = getParams();

        // console.log('params', params);

        if( ! userState.event.isSubmitLoading ){

            setValidated(true);
            
            const form = event.currentTarget;
            
            if (form.checkValidity() === false) {
                event.stopPropagation();
            }

            if(form.checkValidity() === true) {             
                // console.log('params', params)
                dispatch(addEvent(params));
            }
        }
    }

    const changeDayEvents = (fullDate, events, dayPlatoon, nightPlatoon, isHoliday, isDayOt, isNightOt, isDayOff, isLeave) => {
        dispatch(setActiveDate(fullDate));
        dispatch(setDayEvents(events));
        dispatch(setDayLegends({
            dayPlatoon,
            nightPlatoon,
            isHoliday,
            isDayOt,
            isNightOt,
            isDayOff,
            isLeave,
        }));
    }

    useEffect(()=>{
        if(userState.event.submitSuccess && ! userState.event.isSubmitLoading){

            let params = userState.event.eventParams;
            toast.success(() => {

                return <div className="toast-custom">
                    <strong>Event submitted</strong><br />
                    <a className="btn-ics-download" href="#" onClick={(e)=> {
                        downloadIcs(params);
                        e.preventDefault();
                    }}><IconApple width="32" height="32" /> <IconOutlook width="32" height="32" /> Download ICS </a>
                </div>
            }, {
                toastId: 'edit-event-success',
                position: "top-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                onClose: () => {
                    window.location.reload(false);
                }
            }); 
            
            dispatch(resetEventSubmit());

            //refresh calendar
            dispatch(resetCalendarLoaded());
            dispatch(getEvents({
                'year': userState.calendar.year,
                'month': userState.calendar.month,
            })).then( (e) => {
                
                if( e.type === 'user/getEvents/fulfilled'){         

                    //refresh selected day events
                    let date = userState.calendar.activeDate;
                    let monthEvents = e.payload.data.events;
                    let events = monthEvents[date] ? monthEvents[date] : [];

                    let day = new Date(date).getDate(); //xxx
                    let isHoliday = userState.calendar.holiday !== null ? ( typeof userState.calendar.holiday[date] === 'undefined' ? false : true ) : false;
                    let shift = userState.calendar.rooster[day];                    
                    let isDayOt = false;
                    let isNightOt = false;
                    let isDayOff = false;
                    let isLeave = false;

                    events.map(d=>{
                        if(d.type==='ot' && d.shift==='day'){
                            isDayOt = true;
                        }else if(d.type==='ot' && d.shift==='night'){
                            isNightOt = true;
                        }else if(d.type==='dayoff'){
                            isDayOff=true;
                        }else if(d.type==='annual'){
                            isLeave=true;
                        }
                    })

                    if( events.length ){
                        changeDayEvents(date, events, shift.day, shift.night, isHoliday, isDayOt, isNightOt, isDayOff, isLeave)
                    }
                }
            }); 
        }
    }, [userState.event.submitSuccess])

    useEffect(() => {

        if( userState.event.submitErrorMsg ){
            dispatch(resetEventSubmit());

            toast.error(userState.event.submitErrorMsg, {
                toastId: 'submit-event-error',
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });            
        }
        
    }, [userState.event.submitErrorMsg])

    const mediaUploadHandler = (event) => {
        if( typeof event.target.files[0] !== 'undefined'){
            // console.log(event.target.files[0]);
            setFileStorage([...fileStorage, ...[event.target.files[0]]]);
        }
    }

    const stopCamera = () => {
        setCameraOpen(false);
    }

    const stopRecording = () => {
        setVoiceOpen(false);
    }

    const addMediaToStorage = (file) => {
        setFileStorage([...fileStorage, ...[file]]);
    }

    const removeMedia = (index) => {
        let array = fileStorage; // make a separate copy of the array
        console.log('index', index);
        console.log('remove', array);
        array.splice(index, 1);
        setFileStorage([...array]);
    }

    // useEffect(() => {
    //     console.log('media', fileStorage)
    // }, [fileStorage])

    const addEventPopupElement = () => {

        if(!userState.event.addEvent){
            return (<></>);
        }
        
        return (
            <Popup open={userState.event.addEvent} position="center center" closeOnDocumentClick={false}>
                <div className="form-wrap">
                    <h3>Add Event</h3>
                    <Form noValidate validated={validated} className="popup-form form-add-event col-12" onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="event_name">
                            <Form.Control type="text" placeholder="Event name*" required={true} value={eventName} onChange={(event)=>setEventName(event.target.value)} />
                            <Form.Control.Feedback type="invalid">
                                Please enter event name
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="event_note">
                            <Form.Control as="textarea" rows={3} placeholder="Type the note here..." required={false} value={eventNote} onChange={(event)=>setEventNote(event.target.value)} />
                            <Form.Control.Feedback type="invalid">
                                Please enter note
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="event_date">
                            <InputGroup>
                                {/* <DatePicker className="form-control" required={true} placeholder="Date" selected={eventDate} onChange={(date:Date) => setEventDate(date)} /> */}
                                {/* <InputGroup.Text><IconDate /></InputGroup.Text> */}
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={auLocale}>
                                    <DatePicker
                                        label="Date"
                                        className="form-control"
                                        value={eventDate}
                                        onChange={(newValue) => {
                                            setEventDate(newValue);
                                        }}
                                        required={true}
                                        renderInput={(params) => <TextField {...params} required />}
                                        desktopModeMediaQuery=""
                                    />
                                </LocalizationProvider>
                                <Form.Control.Feedback type="invalid">
                                    Please enter valid date
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>

                        <div className="row">
                            <Form.Group className="col-6 mb-3" controlId="start_time">
                                <InputGroup>
                                    {/* <TimePicker clearIcon={false} clockIcon={<IconClock />} className="form-control" placeholder="Start time" required={false} onChange={setEventStartTime} value={eventStartTime} /> */}
                                    {/* <InputGroup.Text><IconClock /></InputGroup.Text> */}
                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
                                        <TimePicker
                                            label="Start Time"
                                            value={eventStartTime}
                                            onChange={(newValue) => {
                                                setEventStartTime(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                            desktopModeMediaQuery=""
                                        />
                                    </LocalizationProvider>
                                    <Form.Control.Feedback type="invalid">
                                        Please enter correct format
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group className="col-6 mb-3" controlId="end_time">
                                <InputGroup>
                                    {/* <TimePicker clearIcon={false} clockIcon={<IconClock />} className="form-control" placeholder="End time" required={false} onChange={setEventEndTime} value={eventEndTime} /> */}
                                    {/* <InputGroup.Text><IconClock /></InputGroup.Text> */}
                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
                                        <TimePicker
                                            label="End Time"
                                            value={eventEndTime}
                                            onChange={(newValue) => {
                                                setEventEndTime(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                            desktopModeMediaQuery=""
                                        />
                                    </LocalizationProvider>
                                    <Form.Control.Feedback type="invalid">
                                        Please enter correct format
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </div>

                        {/* <Form.Group className="mb-3" controlId="event_shift-day">
                            <InputGroup>
                                <Form.Check
                                    type="radio"
                                    name="event_shift"
                                    label="Day"
                                    value="day"
                                    id="event_shift-day"
                                    checked={eventShift==='day'}
                                    onChange={(event) => event.target.checked && setEventShift(event.target.value)}
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="event_shift-night">
                            <InputGroup>
                                <Form.Check
                                    type="radio"
                                    name="event_shift"
                                    label="Night"
                                    value="night"
                                    id="event_shift-night"
                                    checked={eventShift==='night'}
                                    onChange={(event) => event.target.checked && setEventShift(event.target.value)}
                                />
                            </InputGroup>
                        </Form.Group>   */}

                        <Form.Group className="mb-5 mt-2">
                            <InputGroup>
                                <Form.Label htmlFor="event_recurring" className="col-10">Recurring event</Form.Label>
                                <Form.Check
                                    type="switch"
                                    id="event_recurring"
                                    className="col-2"
                                    label=""
                                    checked={eventRecurring}
                                    onChange={(event) => openRecurringPopup(event.target.checked)}
                                />
                            </InputGroup>
                        </Form.Group>
                                            
                        <div className={(recurrenceOpen ? '' : 'd-none') + ' popup-wrap'}>
                            <div className="recurrence-popup">
                                <h4>Custom recurrence</h4>

                                <div className="input-group">
                                    <label>Repeat every</label>
                                    <Form.Control type="text" placeholder="1" required={eventRecurring ? true : false} value={repeatNumber} onChange={(event) => setRepeatNumber(event.target.value)} />
                                    <Form.Select size="lg" value={repeatPeriod} onChange={(event) => setRepeatPeriod(event.target.value)}>
                                        <option value="day">Day</option>
                                        <option value="week">Week</option>
                                        <option value="month">Month</option>
                                        <option value="year">Year</option>
                                    </Form.Select>
                                </div>
                                {repeatPeriod==='week' &&
                                <div className="input-group">
                                    <label>Repeat on</label>
                                    <ul>
                                        <li><a href="#" className={sunday && 'active'} onClick={() => setSunday(! sunday)}>S</a></li>
                                        <li><a href="#" className={monday && 'active'} onClick={() => setMonday(! monday)}>M</a></li>
                                        <li><a href="#" className={tuesday && 'active'} onClick={() => setTuesday(! tuesday)}>T</a></li>
                                        <li><a href="#" className={wednesday && 'active'} onClick={() => setWednesday(! wednesday)}>W</a></li>
                                        <li><a href="#" className={thursday && 'active'} onClick={() => setThursday(! thursday)}>T</a></li>
                                        <li><a href="#" className={friday && 'active'} onClick={() => setFriday(! friday)}>F</a></li>
                                        <li><a href="#" className={saturday && 'active'} onClick={() => setSaturday(! saturday)}>S</a></li>
                                    </ul>
                                </div>}
                                <div className="input-group">
                                    <label>Ends</label>
                                    <div className="row">
                                        <span className="col-4">
                                            <Form.Check
                                                type="radio"
                                                name="recurring_end"
                                                label="Never"
                                                value="never"
                                                id="recurring-end-never"
                                                checked={endType==='never'}
                                                onChange={(event) => event.target.checked && setEndType(event.target.value)}
                                            />
                                        </span>
                                    </div>
                                    <div className="row">
                                        <span className="col-4">
                                            <Form.Check
                                                type="radio"
                                                name="recurring_end"
                                                label="On"
                                                value="on"
                                                id="recurring-end-on"
                                                checked={endType==='on'}
                                                onChange={(event) => event.target.checked && setEndType(event.target.value)}
                                            />
                                        </span>
                                        <div className="col-8">
                                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={auLocale}>
                                                <DatePicker
                                                    label=""
                                                    className=""
                                                    value={recurringEndDate}
                                                    onChange={(newValue) => {
                                                        setRecurringEndDate(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} />}
                                                    desktopModeMediaQuery=""
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <span className="col-4">
                                            <Form.Check
                                                type="radio"
                                                name="recurring_end"
                                                label="After"
                                                value="after"
                                                id="recurring-end-after"
                                                checked={endType==='after'}
                                                onChange={(event) => event.target.checked && setEndType(event.target.value)}
                                            />
                                        </span>
                                        <div className="col-3">
                                            <Form.Control type="text" placeholder="" required={endType === 'after' ? true : false} value={occurencesLimit} onChange={(event => setOccurencesLimit(event.target.value))} />
                                        </div>
                                        <div className="col-5">
                                            occurrences
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="btn-wrap">
                                    <Button variant="secondary" className="btn-cancel" onClick={() => closeRecurringPopup()}> Cancel </Button>
                                    <Button variant="secondary" onClick={() => saveRecurring()}> Done </Button>
                                </div>
                            </div>
                        </div>

                        {userState.event.submitErrorMsg && <Alert className="mt-3" variant="danger">{userState.event.submitErrorMsg}</Alert>}
                        
                        <a className="close-popup" onClick={()=> closeAddPopup()}>Cancel</a>

                        <Button variant="primary" type="submit">
                            Create Event
                        </Button>
                    </Form>
                </div>
            </Popup>
        );
    }

    const addNotePopupElement = () => {

        if(!userState.event.addNote){
            return (<></>);
        }
        
        return (
            <>
            {cameraOpen && <BrowserCamera stopCamera={stopCamera} addMediaToStorage={addMediaToStorage} />}
            {/* {voiceOpen && <VoiceRecorder2 stopRecording={stopRecording} addMediaToStorage={addMediaToStorage} />} */}
            <Popup open={userState.event.addNote} position="center center" closeOnDocumentClick={false}>
                <div className="form-wrap">
                    <h3>Add Note</h3>
                    <Form noValidate validated={validated} className="popup-form form-add-event col-12" onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="event_name">
                            <Form.Control type="text" placeholder="Title" required={true} value={eventName} onChange={(event)=>setEventName(event.target.value)} />
                            <Form.Control.Feedback type="invalid">
                                Please enter title
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="event_note">
                            <div className="note-wrap">
                                <Form.Control as="textarea" rows={8} placeholder="Note" required={false} value={eventNote} onChange={(event)=>setEventNote(event.target.value)} />
                                <ul className="action-wrap">
                                    <li><IconCamera onClick={() => setCameraOpen(true)} /></li>
                                    <li><IconVideo onClick={() => uploadVideoRef.current.click()} /><input style={{display:'none'}} ref={uploadVideoRef} type="file" accept="video/*" onChange={(event) => mediaUploadHandler(event)} /></li>
                                    <li><IconPhoto onClick={() => uploadPhotoRef.current.click()} /><input style={{display:'none'}} ref={uploadPhotoRef} type="file" accept="image/*" onChange={(event) => mediaUploadHandler(event)} /></li>
                                    <li><IconVoice onClick={() => setVoiceOpen(true)} /></li>
                                    {/* <li><IconAttachment /></li> */}
                                </ul>
                            </div>
                            <Form.Control.Feedback type="invalid">
                                Please enter note
                            </Form.Control.Feedback>
                        </Form.Group>

                        <div className="thumb-wrap">
                            {/* {console.log(fileStorage)} */}
                            <ul className='media-thumb'>
                                {fileStorage && fileStorage.map((file, index) => {
                                    let url = URL.createObjectURL(file);

                                    if (file.type.match("video")) {
                                        let videoThumb = typeof file.thumbBase64 !== 'undefined' ? file.thumbBase64 : require('../images/play-button.webp');
                                        return <li key={index}>
                                            <VideoThumbnail videoUrl={url} renderThumbnail={true} thumbnailHandler={(thumbnail) => {
                                                if(isBase64(thumbnail)) {
                                                    file.thumbBase64 = thumbnail;
                                                    setFileStorage([...fileStorage]);
                                                }
                                            }} />
                                            <img alt="not fount" src={videoThumb} />                                            
                                            <IconClose className="btn-remove" onClick={() => removeMedia(index)} />
                                        </li>;                                
                                    } else if (file.type.match("image")) {
                                        return <li key={index}>
                                            <img alt="not fount" src={url} />
                                            <IconClose className="btn-remove" onClick={() => removeMedia(index)} />
                                        </li>;
                                    } else if (file.type.match("audio")) {
                                        return <li key={index}>
                                            <img alt="not fount" src={require('../images/voice.jpg')} />
                                            <IconClose className="btn-remove" onClick={() => removeMedia(index)} />
                                        </li>;
                                    }
                                })}
                            </ul>
                        </div>

                        <Form.Group className="mb-3" controlId="event_date">
                            <InputGroup>
                                {/* <DatePicker className="form-control" required={true} placeholder="Date" selected={eventDate} onChange={(date:Date) => setEventDate(date)} /> */}
                                {/* <InputGroup.Text><IconDate /></InputGroup.Text> */}
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={auLocale}>
                                    <DatePicker
                                        label="Date"
                                        className="form-control"
                                        value={eventDate}
                                        onChange={(newValue) => {
                                            setEventDate(newValue);
                                        }}
                                        // required={true}
                                        renderInput={(params) => <TextField {...params} required />}
                                        desktopModeMediaQuery=""
                                    />
                                </LocalizationProvider>
                                <Form.Control.Feedback type="invalid">
                                    Please enter valid date
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>

                        {/* <Form.Group className="mb-3" controlId="event_shift-day">
                            <InputGroup>
                                <Form.Check
                                    type="radio"
                                    name="event_shift"
                                    label="Day"
                                    value="day"
                                    id="event_shift-day"
                                    checked={eventShift==='day'}
                                    onChange={(event) => event.target.checked && setEventShift(event.target.value)}
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="event_shift-night">
                            <InputGroup>
                                <Form.Check
                                    type="radio"
                                    name="event_shift"
                                    label="Night"
                                    value="night"
                                    id="event_shift-night"
                                    checked={eventShift==='night'}
                                    onChange={(event) => event.target.checked && setEventShift(event.target.value)}
                                />
                            </InputGroup>
                        </Form.Group>   */}
                       

                        {userState.event.submitErrorMsg && <Alert className="mt-3" variant="danger">{userState.event.submitErrorMsg}</Alert>}
                        
                        <a className="close-popup" onClick={()=> closeAddPopup()}>Cancel</a>

                        <Button variant="primary" type="submit">
                            Create Note
                        </Button>
                    </Form>
                </div>
            </Popup>
            </>
        );
    }

    const addOtPopupElement = () => {

        if(!userState.event.addOt){
            return (<></>);
        }
        
        return (
            <Popup open={userState.event.addOt} position="center center" closeOnDocumentClick={false}>
                <div className="form-wrap">
                    <h3>Add Recall/OT</h3>
                    <Form noValidate validated={validated} className="popup-form form-add-event col-12" onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="event_name">
                            <Form.Control type="text" placeholder="Title" required={true} value={eventName} onChange={(event)=>setEventName(event.target.value)} />
                            <Form.Control.Feedback type="invalid">
                                Please enter title
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="event_note">
                            <Form.Control as="textarea" rows={8} placeholder="Note" required={false} value={eventNote} onChange={(event)=>setEventNote(event.target.value)} />
                            <Form.Control.Feedback type="invalid">
                                Please enter note
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="event_date">
                            <InputGroup>
                                {/* <DatePicker className="form-control" required={true} placeholder="Date" selected={eventDate} onChange={(date:Date) => setEventDate(date)} /> */}
                                {/* <InputGroup.Text><IconDate /></InputGroup.Text> */}
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={auLocale}>
                                    <DatePicker
                                        label="Date"
                                        className="form-control"
                                        value={eventDate}
                                        onChange={(newValue) => {
                                            setEventDate(newValue);
                                        }}
                                        // required={true}
                                        renderInput={(params) => <TextField {...params} required />}
                                        desktopModeMediaQuery=""
                                    />
                                </LocalizationProvider>
                                <Form.Control.Feedback type="invalid">
                                    Please enter valid date
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="event_shift-day">
                            <InputGroup>
                                <Form.Check
                                    type="radio"
                                    name="event_shift"
                                    label="Day"
                                    value="day"
                                    id="event_shift-day"
                                    checked={eventShift==='day'}
                                    onChange={(event) => event.target.checked && setEventShift(event.target.value)}
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="event_shift-night">
                            <InputGroup>
                                <Form.Check
                                    type="radio"
                                    name="event_shift"
                                    label="Night"
                                    value="night"
                                    id="event_shift-night"
                                    checked={eventShift==='night'}
                                    onChange={(event) => event.target.checked && setEventShift(event.target.value)}
                                />
                            </InputGroup>
                        </Form.Group>                

                        {userState.event.submitErrorMsg && <Alert className="mt-3" variant="danger">{userState.event.submitErrorMsg}</Alert>}
                        
                        <a className="close-popup" onClick={()=> closeAddPopup()}>Cancel</a>

                        <Button variant="primary" type="submit">
                            Create Recall/OT
                        </Button>
                    </Form>
                </div>
            </Popup>
        );
    }

    const addDayOffPopupElement = () => {

        if(!userState.event.addDayOff){
            return (<></>);
        }
        
        return (
            <Popup open={userState.event.addDayOff} position="center center" closeOnDocumentClick={false}>
                <div className="form-wrap">
                    <h3>Add Day Off</h3>
                    <Form noValidate validated={validated} className="popup-form form-add-event col-12" onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="event_name">
                            <Form.Control type="text" placeholder="Title" required={true} value={eventName} onChange={(event)=>setEventName(event.target.value)} />
                            <Form.Control.Feedback type="invalid">
                                Please enter title
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="event_note">
                            <Form.Control as="textarea" rows={8} placeholder="Note" required={false} value={eventNote} onChange={(event)=>setEventNote(event.target.value)} />
                            <Form.Control.Feedback type="invalid">
                                Please enter note
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="event_date">
                            <InputGroup>
                                {/* <DatePicker className="form-control" required={true} placeholder="Date" selected={eventDate} onChange={(date:Date) => setEventDate(date)} /> */}
                                {/* <InputGroup.Text><IconDate /></InputGroup.Text> */}
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={auLocale}>
                                    <DatePicker
                                        label="Start Date"
                                        className="form-control"
                                        value={eventDate}
                                        onChange={(newValue) => {
                                            setEventDate(newValue);
                                        }}
                                        // required={true}
                                        renderInput={(params) => <TextField {...params} required />}
                                        desktopModeMediaQuery=""
                                    />
                                </LocalizationProvider>
                                <Form.Control.Feedback type="invalid">
                                    Please enter valid date
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="event_end_date">
                            <InputGroup>
                                {/* <DatePicker className="form-control" required={true} placeholder="Date" selected={eventDate} onChange={(date:Date) => setEventDate(date)} /> */}
                                {/* <InputGroup.Text><IconDate /></InputGroup.Text> */}
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={auLocale}>
                                    <DatePicker
                                        label="End Date"
                                        className="form-control"
                                        value={eventEndDate}
                                        onChange={(newValue) => {
                                            setEventEndDate(newValue);
                                        }}
                                        // required={true}
                                        renderInput={(params) => <TextField {...params} />}
                                        desktopModeMediaQuery=""
                                    />
                                </LocalizationProvider>
                                <Form.Control.Feedback type="invalid">
                                    Please enter valid date
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>   

                         {/* <Form.Group className="mb-3" controlId="event_shift-day">
                            <InputGroup>
                                <Form.Check
                                    type="radio"
                                    name="event_shift"
                                    label="Day"
                                    value="day"
                                    id="event_shift-day"
                                    checked={eventShift==='day'}
                                    onChange={(event) => event.target.checked && setEventShift(event.target.value)}
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="event_shift-night">
                            <InputGroup>
                                <Form.Check
                                    type="radio"
                                    name="event_shift"
                                    label="Night"
                                    value="night"
                                    id="event_shift-night"
                                    checked={eventShift==='night'}
                                    onChange={(event) => event.target.checked && setEventShift(event.target.value)}
                                />
                            </InputGroup>
                        </Form.Group>                       */}

                        {userState.event.submitErrorMsg && <Alert className="mt-3" variant="danger">{userState.event.submitErrorMsg}</Alert>}
                        
                        <a className="close-popup" onClick={()=> closeAddPopup()}>Cancel</a>

                        <Button variant="primary" type="submit">
                            Create Day Off
                        </Button>
                    </Form>
                </div>
            </Popup>
        );
    }

    const addAnnLeavePopupElement = () => {

        if(!userState.event.addAnnLeave){
            return (<></>);
        }
        
        return (
            <Popup open={userState.event.addAnnLeave} position="center center" closeOnDocumentClick={false}>
                <div className="form-wrap">
                    <h3>Add Annual Leave</h3>
                    <Form noValidate validated={validated} className="popup-form form-add-event col-12" onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="event_name">
                            <Form.Control type="text" placeholder="Title" required={true} value={eventName} onChange={(event)=>setEventName(event.target.value)} />
                            <Form.Control.Feedback type="invalid">
                                Please enter title
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="event_note">
                            <Form.Control as="textarea" rows={8} placeholder="Note" required={false} value={eventNote} onChange={(event)=>setEventNote(event.target.value)} />
                            <Form.Control.Feedback type="invalid">
                                Please enter note
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="event_date">
                            <InputGroup>
                                {/* <DatePicker className="form-control" required={true} placeholder="Date" selected={eventDate} onChange={(date:Date) => setEventDate(date)} /> */}
                                {/* <InputGroup.Text><IconDate /></InputGroup.Text> */}
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={auLocale}>
                                    <DatePicker
                                        label="Start Date"
                                        className="form-control"
                                        value={eventDate}
                                        onChange={(newValue) => {
                                            setEventDate(newValue);
                                        }}
                                        // required={true}
                                        renderInput={(params) => <TextField {...params} required />}
                                        desktopModeMediaQuery=""
                                    />
                                </LocalizationProvider>
                                <Form.Control.Feedback type="invalid">
                                    Please enter valid date
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="event_end_date">
                            <InputGroup>
                                {/* <DatePicker className="form-control" required={true} placeholder="Date" selected={eventDate} onChange={(date:Date) => setEventDate(date)} /> */}
                                {/* <InputGroup.Text><IconDate /></InputGroup.Text> */}
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={auLocale}>
                                    <DatePicker
                                        label="End Date"
                                        className="form-control"
                                        value={eventEndDate}
                                        onChange={(newValue) => {
                                            setEventEndDate(newValue);
                                        }}
                                        // required={true}
                                        renderInput={(params) => <TextField {...params} />}
                                        desktopModeMediaQuery=""
                                    />
                                </LocalizationProvider>
                                <Form.Control.Feedback type="invalid">
                                    Please enter valid date
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                       
                        {/* <Form.Group className="mb-3" controlId="event_shift-day">
                            <InputGroup>
                                <Form.Check
                                    type="radio"
                                    name="event_shift"
                                    label="Day"
                                    value="day"
                                    id="event_shift-day"
                                    checked={eventShift==='day'}
                                    onChange={(event) => event.target.checked && setEventShift(event.target.value)}
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="event_shift-night">
                            <InputGroup>
                                <Form.Check
                                    type="radio"
                                    name="event_shift"
                                    label="Night"
                                    value="night"
                                    id="event_shift-night"
                                    checked={eventShift==='night'}
                                    onChange={(event) => event.target.checked && setEventShift(event.target.value)}
                                />
                            </InputGroup>
                        </Form.Group>   */}

                        {userState.event.submitErrorMsg && <Alert className="mt-3" variant="danger">{userState.event.submitErrorMsg}</Alert>}
                        
                        <a className="close-popup" onClick={()=> closeAddPopup()}>Cancel</a>

                        <Button variant="primary" type="submit">
                            Create Annual Leave
                        </Button>
                    </Form>
                </div>
            </Popup>
        );
    }

    const popupOpened = userState.event.addEvent || userState.event.addOt || userState.event.addAnnLeave || userState.event.addDayOff || userState.event.addNote; 

    return (
        <>
            <LoadingOverlay
                active={userState.event.isSubmitLoading}
                // active={true}
                // spinner={<BounceLoader />}
                text=""
                >                    
            </LoadingOverlay>

            {addEventPopupElement()}

            {addNotePopupElement()}

            {addOtPopupElement()}

            {addDayOffPopupElement()}

            {addAnnLeavePopupElement()}

            <div className={(catOpen ? '' : 'd-none') + ' add-event-category'}>
                <h3>Select Category</h3>
                <ul className="event-category-select">
                    <li><a onClick={(e) => {openAddEventPopup('note'); e.preventDefault();}} className="note" href="#"><span className="circle"></span>Note</a></li>
                    <li><a onClick={(e) => {openAddEventPopup('event'); e.preventDefault();}} className="event" href="#"><span className="circle"></span>Event</a></li>
                    <li><a onClick={(e) => {openAddEventPopup('annual'); e.preventDefault();}} className="annual" href="#"><span className="circle"></span>Annual Leave</a></li>
                    <li><a onClick={(e) => {openAddEventPopup('dayoff'); e.preventDefault();}} className="dayoff" href="#"><span className="circle"></span>Day Off</a></li>
                    <li><a onClick={(e) => {openAddEventPopup('training'); e.preventDefault();}} className="training" href="#"><span className="circle"></span>Training</a></li>
                    <li><a onClick={(e) => {openAddEventPopup('ot'); e.preventDefault();}} className="ot" href="#"><span className="circle"></span>Recall/OT</a></li>
                </ul>
            </div>
            {! userState.calendar.isCalendarLoading && userState.calendar.isCalendarLoaded && popupOpened === false && 
            <div className="fixed-add-wrap">
                <span id="add" className="button" onClick={() => openCategorySelection()}><IconPlus /></span>
            </div>}
        </>
    )
}

export default AddEvent;