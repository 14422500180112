export const DEV_MODE = false;

export const API_KEY = "cd917aac-7f84-46a9-b7e1-c6aded667500";
// export const BASE_API_URL = 'https://djmir.mobi/server/api/v1/';
export const BASE_URL = 'https://admin.djmir.mobi/';
export const BASE_API_URL = BASE_URL + 'api/v1/';
export const VIMEO_TOKEN = 'ac1bb2d458cfcc0caa1fa70e33a05877';
export const PUSH_NOTIFICATION_PUBLIC_KEY = 'BG75iZHfQhVfMFjHxZPzABzX11D1Ju6-LgYjQz3k2P2EWewVqg15_voAQU7kvfRvHgFrpJXCbiLwHcl8Nqq2XX0';
export const WA_DOMAIN = 'https://www.djmir.com.au/';
export const WA_ACCOUNT_ID = 237168;
export const WA_CLIENT_ID = '11jgiypxz6';
export const WA_REDIRECT_URI = ! DEV_MODE ? 'https://djmir.mobi/verify' : 'http://localhost:3000/verify';
export const WA_SSO = true;