import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Popup from 'reactjs-popup';
import "react-datepicker/dist/react-datepicker.css";
import 'reactjs-popup/dist/index.css';
import '../css/note.css';
import { resetViewNote } from '../slices/userSlice';

import { ReactComponent as IconClose } from '../icons/ico-close.svg';
import { ReactComponent as IconPhoto } from '../icons/ico-photo.svg';
import { ReactComponent as IconVideo } from '../icons/ico-video.svg';
import { ReactComponent as IconVoice } from '../icons/ico-voice.svg';
// import ImageGallery from './ImageGallery';
import VideoGallery from './VideoGallery';
import VoiceGallery from './VoiceGallery';

const ViewNote = (props) => {

    const userState = useSelector(state => state.user);

    const dispatch = useDispatch();

    const [noteObj, setNoteObj] = useState(props.note);

    const [viewNotePopup, setViewNotePopup] = useState(noteObj!==false && noteObj.post_id);
    const [title, setTitle]  = useState(noteObj !== false ? noteObj.title : '');
    const [note, setNote] = useState(noteObj !== false ? noteObj.note : '');

    const [viewGallery, setViewGallery] = useState(false);
    const [viewVideos, setViewVideos] = useState(false);
    const [viewVoices, setViewVoices] = useState(false);

    const closeViewPopup = () => {
        setTitle('');
        setNote('');

        dispatch(resetViewNote());
        setViewNotePopup(false);
        props.resetNote();
    }

    useEffect( () => {
        setNoteObj(props.note);
        setViewNotePopup(props.note!==false && props.note.post_id);
        setTitle(props.note.title);
        setNote(props.note.note);
    }, [props.note])

    const resetGallery = () => {
        setViewGallery(false);
    }

    const resetVideos = () => {
        setViewVideos(false);
    }

    const resetVoices = () => {
        setViewVoices(false);
    }

    const viewNotePopupElement = () => {
        
        if(!viewNotePopup){
            return (<></>);
        }
        
        return (
            <>
                {/* <ImageGallery event={viewGallery} resetGallery={resetGallery} /> */}
                <VideoGallery event={viewVideos} resetGallery={resetVideos} />
                <VoiceGallery event={viewVoices} resetGallery={resetVoices} />
                <Popup className="view-note" open={viewNotePopup} position="center center" closeOnDocumentClick={false}>                
                    <a className="close-popup btn-close" onClick={()=> closeViewPopup()}></a>
                    <div className="form-wrap">
                        <h3>{title}</h3>                    
                        <div className="view-note text-center">
                            {note}
                        </div>
                        {noteObj && <ul className="media-view">
                            {/* {d.videos && <li><IconCamera /></li>} */}
                            {noteObj.photos.length > 0 && <li><IconPhoto onClick={()=>{setViewGallery(noteObj)}}/></li>}
                            {noteObj.videos.length > 0 && <li><IconVideo onClick={()=>{setViewVideos(noteObj)}}/></li>}
                            {noteObj.voices.length > 0 && <li><IconVoice onClick={()=>{setViewVoices(noteObj)}}/></li>}
                            {/* <li><IconAttachment /></li> */}
                        </ul>}
                    </div>
                </Popup>
            </>
        );
    }

    return (
        <>
            {viewNotePopupElement()}
        </>
    )
}

export default ViewNote;