import React from 'react';
import Iframe from 'react-iframe'

const Shop = (props) => {

    return (
        <div className="row">           
            <div className="col-12">
                <h1 className="page-title">Shop</h1>

                <Iframe 
                    url="https://shop.djmir.asn.au/" 
                    width="100%" 
                    height="100%" 
                    // styles={{height: "100vh"}}
                />
            </div>
        </div>
    )
}

export default Shop;