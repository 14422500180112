import React, { useState, useEffect } from 'react';
import useInfiniteScroll from "../helpers/useInfinite";
import parse from 'html-react-parser';
import '../css/video.css';
import Alert from 'react-bootstrap/Alert';
import { get_videos } from '../helpers/api.service';
import Iframe from 'react-iframe';
import LazyLoad from 'react-lazy-load';

const OtherVideos = (props) => {


    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [error, setError] = useState('');
    const [isFetching, setIsFetching] = useInfiniteScroll(moreData);
    const [isLoading, setIsLoading] = useState(true);

    const loadData = () => {
        get_videos(1).then(res => {
            if(res.data.data.status === 1){
                setData(res.data.data.videos.map((video) => {
                    video.isPlay=null;
                    return video;
                }));
                setPage(page + 1)
                setIsLoading(false);
            }
        }).catch((error) => {
            setError(error.message);
            setIsLoading(false);
        });
    }
    function moreData() {
        get_videos(page).then(res => {
            if(res.data.data.status === 1){
                if(res.data.data.videos.length > 0){
                    setData([...data, ...res.data.data.videos]);
                    setPage(page + 1)
                }
                setIsFetching(false)
                setIsLoading(false);
            }
        }).catch((error) => {
            setError(error.message);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        if(page === 1) loadData()
    }, [])

    const viewFormat = (count) => {
        return new Intl.NumberFormat().format(count) + ' views';
    }

    const dateFormat = (date) =>{

        var options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
        var today  = new Date(date);

        return today.toLocaleDateString("en-US", options);
    }

    return (
        <div className="video-wrap">
            {data.length === 0 && error==='' && isLoading && <p className="text-center">Loading...</p>}            
            {data.length === 0 && error==='' && ! isLoading && <p className="text-center">No Videos</p>}
            {data.length === 0 && error!=='' && <Alert className="mt-3" variant="warning">{error}</Alert>}
            <ul className="video-list">
                {data.map((video, key) => (
                
                <li className="single-video" key={key}>
                    <LazyLoad>
                    <div>
                        <div className="video">
                            {video.isPlay == null && <img 
                                // className={}
                                onClick={() => { 
                                    video.isPlay=1;
                                    setData([...data])
                                }}
                                src={video.thumb === '' ? require('../images/play-button.webp') : video.thumb} 
                                alt={video.title} 
                                title={video.title} />}
                            {video.isPlay === 1 &&
                                <Iframe 
                                    url={video.url} 
                                    width="100%" 
                                    height="100%" 
                                    // styles={{height: "100vh"}}
                                />}
                            {/* <div style="padding:56.25% 0 0 0;position:relative;"><iframe src={video.player_embed_url + "?h=8f88230f7c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title={video.name}></iframe></div><script src="https://player.vimeo.com/api/player.js"></script> */}
                        </div>
                        <div className="video-info">
                            <img src={require('../images/video-user.png')} alt="djmir" />
                            <div className="video-desc">
                                <h3 className="video-title">{video.title}</h3>
                                <p className="video-meta"><span className="publish-date">{dateFormat(video.date)}</span></p>
                            </div>
                        </div>
                    </div>
                    </LazyLoad>
                </li>
                ))}
            </ul>
        </div>
    )
}

export default OtherVideos;