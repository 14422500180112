
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
// import { Col } from 'react-bootstrap';
import { submitContactForm, resetContactForm } from '../slices/userSlice';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay-ts';

import { ReactComponent as Logo } from '../images/logo.svg';

import { ReactComponent as IconUser } from '../icons/ico-user-3.svg';
import { ReactComponent as IconPhone } from '../icons/ico-phone.svg';
import { ReactComponent as IconCategory } from '../icons/ico-category.svg';
import { ReactComponent as IconMessage } from '../icons/ico-message.svg';

const ContactForm = (props) => {

    const dispatch = useDispatch();
    const userState = useSelector(state => state.user);

    // console.log('user',userState.user);

    const [formFields, setFormFields] = useState({
        fullname: '',
        phone: '',
        category: '',
        confirm_method: '',
        message: '',
    });

    // const [submitView, setSubmitView] = useState(false);

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();

        let params = formFields;

        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.stopPropagation();
        }

        setValidated(true);

        if (form.checkValidity() === true) {
            dispatch(submitContactForm(params))
        }
    }

    const fieldChange = (e) => {

        setFormFields(formFields => ({
            ...formFields,
            [e.target.name]: e.target.value,
        }));
    }

    useEffect(() => {
        // dispatch(resetContactForm());
    }, [])

    useEffect(() => {
        if (userState.contactForm.submitted && !userState.contactForm.isLoading) {
            // dispatch(resetContactForm());

            // setSubmitView(true);
        }
    }, [userState.contactForm.submitted, userState.contactForm.isLoading, dispatch])

    useEffect(() => {
        if (userState.contactForm.errMsg) {
            dispatch(resetContactForm());
            toast.error(userState.contactForm.errMsg, {
                toastId: 'submit-form-error',
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    }, [userState.contactForm.errMsg])

    return (
        <div className="row">
            <LoadingOverlay
                active={userState.contactForm.isLoading}
                // active={true}
                // spinner={<BounceLoader />}
                text=""
            >
            </LoadingOverlay>
            <div className="col-12">
                <div className="page-logo">
                    <Logo />
                </div>
            </div>
            {!userState.contactForm.submitted &&
            <div className="col-12">
                <h1 className="page-title extra-space">Ask a question</h1>

                <div className="contact-form">
                    <Form noValidate validated={validated} className="form-profile col-12" onSubmit={handleSubmit}>

                        <Form.Group className="form-group mb-3_5" controlId="fullname">
                            <div className="w-icon">
                                <IconUser />
                                <Form.Control type="text" name="fullname" placeholder="Full name" required={true} value={formFields.fullname} onChange={(e) => fieldChange(e)} />
                            </div>
                            <Form.Control.Feedback type="invalid">
                                Please enter full name
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-group mb-3_5" controlId="phone">
                            <div className="w-icon">
                                <IconPhone />
                                <Form.Control type="text" name="phone" placeholder="Phone" required={true} value={formFields.phone} onChange={(e) => fieldChange(e)} />
                            </div>
                            <Form.Control.Feedback type="invalid">
                                Please enter phone
                            </Form.Control.Feedback>
                        </Form.Group>

                        {/* <Form.Group className="form-group mb-2" controlId="email">
                            <div className="w-icon">
                                <IconMessage />
                                <Form.Control type="text" name="email" placeholder="Email" required={true} value={formFields.email} onChange={(e) => fieldChange(e)} readOnly={true} />
                            </div>
                            <Form.Control.Feedback type="invalid">
                                Please enter email
                            </Form.Control.Feedback>
                        </Form.Group> */}

                        <Form.Group className="form-group mb-3_5" controlId="category">
                            <div className="w-icon">
                                <IconCategory />
                                <Form.Select name="category" required={true} value={formFields.category} onChange={(e) => fieldChange(e)}>
                                    <option value="">Category</option>
                                    <option value="wages">Wages</option>
                                    <option value="staff">Staff</option>
                                    <option value="issues">Issues</option>
                                    <option value="other">Other</option>
                                </Form.Select>
                            </div>
                            <Form.Control.Feedback type="invalid">
                                Please enter category
                            </Form.Control.Feedback>
                        </Form.Group>

                        {/* <Form.Group className="form-group mb-3_5" controlId="confirm_method">
                            <div className="w-icon">
                                <IconMessage />
                                <Form.Select name="confirm_method" required={true} value={formFields.confirm_method} onChange={(e) => fieldChange(e)}>
                                    <option value="">Preferred Communication</option>
                                    <option value="sms">SMS</option>
                                    <option value="email">Email</option>
                                </Form.Select>
                            </div>
                            <Form.Control.Feedback type="invalid">
                                Please select prefered communication method
                            </Form.Control.Feedback>
                        </Form.Group> */}

                        <Form.Group className="form-group mb-3_5" controlId="message">
                            <Form.Control as="textarea" name="message" rows={4} placeholder="Tell us how we can help" required={false} value={formFields.message} onChange={(e) => fieldChange(e)} />
                            <Form.Control.Feedback type="invalid">
                                Please enter message
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Button variant="primary" className="mt-5" type="submit">
                            Submit
                        </Button>

                    </Form>
                </div>
            </div>}

            {userState.contactForm.submitted && 
            <div className="col-12">
                <div className="center-screen text-center">
                    <h2 className="big-title">Request Submitted</h2>
                    <p className="large-text mb-5">Your request for help has been submitted to our team.</p>
                    <p>One of our staff will get in touch with you shortly</p>
                    <Link className="w-100" to="/"><Button className="f-width mt-5" variant="primary">Return to Dashboard</Button></Link>
                </div>
            </div>
            }
        </div>
    )
}

export default ContactForm;