import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import '../css/mobileNav.css';

import { ReactComponent as IconApp } from '../icons/ico-app.svg';
import { ReactComponent as IconBell } from '../icons/ico-bell.svg';
import { ReactComponent as IconPhone } from '../icons/ico-phone.svg';
import { ReactComponent as IconUser } from '../icons/ico-user-3.svg';
import { ReactComponent as IconSpeaker } from '../icons/ico-speaker.svg';
import { ReactComponent as IconMessage } from '../icons/ico-message.svg';

const MobileNav = (props) => {

    const userState = useSelector(state => state.user);
    const location = useLocation();

    const getPathName = () => {
        return location.pathname;
    }

    return (
        <nav id="nav-mobile">
            <div className="container">
                <ul>
                    <li>
                        <Link to="/alerts" className={getPathName() === '/alerts' ? "active" : ''}><IconBell className="svg-ico svg-ico-25 svg-ico-bell" /></Link>
                        {userState.notification.count > 0 && <span className="alert-count">{userState.notification.count}</span>}
                    </li>
                    <li><Link to="/profile" className={getPathName() === '/profile' ? "active" : ''}><IconUser className="svg-ico svg-ico-25 svg-ico-user" /></Link></li>
                    
                    <li><Link to="/" className={getPathName() === '/' ? "active" : ''}><IconApp className="svg-ico svg-ico-25 svg-ico-app" /></Link></li>
                    <li><Link to="/hotline" className={getPathName() === '/hotline' ? "active" : ''}><IconPhone className="svg-ico svg-ico-25 svg-ico-home" /></Link></li>
                    <li>
                        <Link to="/contact-us" className={getPathName() === '/contact-us' ? "active" : ''}><IconMessage className="svg-ico svg-ico-25 svg-ico-speaker" /></Link>
                    </li>

                    
                </ul>
            </div>
        </nav>

    )
}

export default MobileNav;