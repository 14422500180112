// import { StaticDatePicker } from '@mui/x-date-pickers';
import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDayEvents, setDayLegends, setActiveDate, setDateFirstLoaded, saveRooster } from '../slices/userSlice';
import { formatDate, getDifferenceInDays } from '../helpers/time';

import { ReactComponent as IconMoon } from '../icons/ico-moon.svg';

const CalendarMonth = (props) => {

    const userState = useSelector(state => state.user);
    const dispatch = useDispatch();

    const date = new Date()
    const day = date.getDate();
    const month = date.getMonth()+1;
    const year = date.getFullYear();

    const currentDate = formatDate(new Date(year, month-1, day));
    const platoonStart = {
        'a': new Date('2022-01-08'),
        'b': new Date('2022-01-06'),
        'c': new Date('2022-01-04'),
        'd': new Date('2022-01-02'),
    }
    const max_shift = 4;
    // const current_start = new Date(`${props.year}-${props.month}-1`);
    const current_start = new Date(props.year, props.month - 1, 1);

    const [rooster, setRooster] = useState({});    

    const [defaultLegend, setDefaultLegend] = useState({
        a: 0,
        b: 0,
        c: 0,
        d: 0,
        // day: 1,
    });

    const [showLegend, setShowLegend] = useState({
        a: defaultLegend.a,
        b: defaultLegend.b,
        c: defaultLegend.c,
        d: defaultLegend.d,
        // day: defaultLegend.day,
    });

    const getFullDate = (status, currDate, month, year) => {

        let fullDate='';
        let newYear;

        if(status===0){
            let newMonth = month-1;
            newYear = year;
            if(newMonth<1){
                newYear--;
            } 
            fullDate = new Date(newYear, newMonth - 1, currDate);
        }else if(status===1){
            fullDate = new Date(year, month - 1, currDate);
        }else if(status===2 ){
            
            let newMonth = month+1;
            newYear = year;
            if(newMonth>12){
                newYear++;
            } 
            fullDate = new Date(year, newMonth - 1, currDate);
        }

        if(fullDate!==''){
            fullDate = formatDate(fullDate);
        }

        // console.log(fullDate, 'fullDate')

        return fullDate;
    }

    const initRoosterPosition = (platoon) => {
        let startDate = 1;
        let maxDate = new Date(props.year, props.month, 0).getDate();
        var tmp = rooster;
        let shift = '';
        let step = max_shift *2;
        let difference = getDifferenceInDays(current_start, platoonStart[platoon]);

        // console.log(platoon,'platoon')
        // console.log(difference,'difference');
        // console.log(maxDate,'maxDate');

        let mod = (difference + 1) % (step);

        if(difference<0){
            mod = step + mod;
        }
        
        // console.log(mod,'mod');

        if( mod >= 1 && mod <= 4 ){
            if(mod>=1 && mod <=2){
                shift = 'day';
            }else if(mod>=3 && mod <=4){
                shift = 'night';
            }
        }else{

        }

        for(let i=startDate; i<=maxDate; i++){

            if( mod >= 1 && mod <= 4 ){
                if(mod>=1 && mod <=2){
                    shift = 'day';
                }else if(mod>=3 && mod <=4){
                    shift = 'night';
                }

                // console.log(i, 'day');
                // console.log({[shift]: platoon})

                tmp[i] = {...tmp[i], [shift]: platoon};
            }

            mod++;            

            if(mod > step){
                mod=1;
            }
        }

        // console.log(tmp);

        setRooster(tmp);
    }

    const initRooster = () => {
        setRooster({})
        initRoosterPosition('d');
        initRoosterPosition('c');
        initRoosterPosition('b');
        initRoosterPosition('a');
    }

    useEffect(()=>{
        if(props.fullMode){
            setShowLegend({
                a: props.activeLegend.a,
                b: props.activeLegend.b,
                c: props.activeLegend.c,
                d: props.activeLegend.d,
                // day: props.activeLegend.day,
            })
        }else{
            setShowLegend({
                a: defaultLegend.a,
                b: defaultLegend.b,
                c: defaultLegend.c,
                d: defaultLegend.d,
                // day: defaultLegend.day,
            })
        }
    },[props.fullMode, props.activeLegend])

    useEffect(()=>{
        initRooster();        
    }, [props.month, props.year])

    const changeDayEvents = (fullDate, events, dayPlatoon, nightPlatoon, isHoliday, isDayOt, isNightOt, isDayOff, isLeave) => {
        dispatch(setActiveDate(fullDate));
        dispatch(setDayEvents(events));
        dispatch(setDayLegends({
            dayPlatoon,
            nightPlatoon,
            isHoliday,
            isDayOt,
            isNightOt,
            isDayOff,
            isLeave,
        }));

        dispatch(saveRooster(rooster));
    }

    // useEffect(() => {
    //     //refresh selected day events
    //     if( ! userState.calendar.isCalendarLoading && userState.calendar.isCalendarLoaded && userState.calendar.events ){
    //         let date = userState.calendar.activeDate;
    //         let events = userState.calendar.events[date] ? userState.calendar.events[date] : [];
    //         dispatch(setDayEvents(events));
    //     }
    // }, [userState.calendar.isCalendarLoaded, userState.calendar.isCalendarLoading, userState.calendar.events])

    useEffect(() => {
        if( rooster && ! userState.calendar.isDateFirstLoaded && userState.calendar.events){

            let date = userState.calendar.activeDate;
            let day = new Date(date).getDate(); //xxx
            // console.log('holiday', userState.calendar.holiday[date])
            let isHoliday = userState.calendar.holiday !== null ? 
                                ( typeof userState.calendar.holiday[date] === 'undefined' ? false 
                                    : (userState.calendar.holiday[date].state.length ? (userState.calendar.holiday[date].state.includes(userState.calendar.holidayState) ? true : false ) 
                                        : true) )
                            : false;
            let shift = rooster[day];
            if ( typeof shift === 'undefined' ){ // fix error on IOS
                shift = {};
            }
            let events = userState.calendar.events[date] ? userState.calendar.events[date] : [];
            
            let isDayOt = false;
            let isNightOt = false;
            let isDayOff = false;
            let isLeave = false;

            events.map(d=>{
                if(d.type==='ot' && d.shift==='day'){
                    isDayOt = true;
                }else if(d.type==='ot' && d.shift==='night'){
                    isNightOt = true;
                }else if(d.type==='dayoff'){
                    isDayOff=true;
                }else if(d.type==='annual'){
                    isLeave=true;
                }
            })
            // console.log(rooster);
            // console.log(day, 'day')
            // console.log(date, 'activeDate')
            // console.log(shift, 'shift')
            // console.log(userState.calendar.events, 'events');
            changeDayEvents(date, events, shift.day, shift.night, isHoliday, isDayOt, isNightOt, isDayOff, isLeave);

            dispatch(setDateFirstLoaded());
        }
    }, [rooster, userState.calendar.events, userState.calendar.isFirstLoaded])

    const generateCalendar = (year, month) => {

        // if(!roosterGenerated){
        //     return <></>
        // }

        let mon = month - 1; // months in JS are 0..11, not 1..12
        let d = new Date(year, mon);

        let maxPreviousDate = new Date(year, mon, 0).getDate();


        let prevDate = [];
        for (let i = 0; i < getDay(d); i++) { prevDate.push(maxPreviousDate--) }
        prevDate.reverse();

        let maxDate = new Date(year, mon + 1, 0).getDate();

        let totalDays = maxDate + prevDate.length;

        let totalWeek = Math.ceil(totalDays / 7);

        let maxDays = totalWeek * 7;

        let dates = [];

        let prevDateCount = 0;
        let date = 1;

        let status = 0; // 0 prevDate, 1 currDate, 2 nextDate 

        for (let i = 0; i < maxDays; i++) {

            if (prevDate.length && prevDateCount < prevDate.length) {
                dates.push(prevDate[prevDateCount]);
                prevDateCount++;
            } else {
                dates.push(date);

                date++;

                if (date > maxDate) {
                    date = 1;
                }
            }
        }

        let dateIndex = 0;
        // console.log(dates);

        return (
            <table className="calendar-table">
                <thead>
                    <tr>
                        <th>Mon</th><th>Tue</th><th>Wed</th><th>Thu</th><th>Fri</th><th>Sat</th><th>Sun</th>
                    </tr>
                </thead>
                <tbody>
                    {[...Array(totalWeek).keys()].map((w) => {
                        return (
                            <tr key={'week_' + w}>
                                {[...Array(7).keys()].map((i) => {

                                    let currDate = dates[dateIndex];

                                    if (status === 0 && currDate === 1) {
                                        status++;
                                    }

                                    let statusClass = '';

                                    switch (status) {
                                        case 0: statusClass = 'prev-date'; break;
                                        case 1: statusClass = 'curr-date'; break;
                                        case 2: statusClass = 'next-date'; break;
                                        default: statusClass = 'prev-date'; break;
                                    }
                                    
                                    let fullDate = getFullDate(status, currDate, month, year);

                                    dateIndex++;

                                    if( currentDate === fullDate ){
                                        statusClass += ' today';
                                    }

                                    if( status === 1 && fullDate === userState.calendar.activeDate){
                                        statusClass += ' active';
                                    }

                                    let event = [];
                                    let platoon = [];
                                    
                                    let shift = rooster[currDate];

                                    if ( typeof shift === 'undefined' ){ // fix error on IOS
                                        shift = {};
                                    }
                                    
                                    if(status===1 && shift){
                                        let legend=[];
                                        if(showLegend[shift.day]){
                                            legend.push(<span key={i+'-day'} className={"platoon-"+shift.day}></span>);
                                        }
                                        if(showLegend[shift.night]){
                                            legend.push(<span key={i+'-night'} className={"platoon-"+shift.night}><IconMoon /></span>);
                                        }

                                        platoon.push(legend);
                                    }
                                    
                                    let events = typeof userState.calendar.events !== 'undefined' ? userState.calendar.events[fullDate] : [];
                                    
                                    let isDayOt = false;
                                    let isNightOt = false;
                                    let isDayOff = false;
                                    let isLeave = false;

                                    if(status===1 && events){
                                        let eventExists = 0;
                                        let noteExists = 0;
                                        let otExists = 0;

                                        events.map((d) => {
                                            if(d.type==='note'){
                                                noteExists = 1;
                                            }else if(d.type==='ot'){
                                                otExists = 1;
                                            }else{
                                                // eventExists = 1;
                                                noteExists = 1; // all events/note thread as purple
                                            }

                                            if(d.type==='ot' && d.shift==='day'){
                                                isDayOt = true;
                                            }else if(d.type==='ot' && d.shift==='night'){
                                                isNightOt = true;
                                            }else if(d.type==='dayoff'){
                                                isDayOff=true;
                                            }else if(d.type==='annual'){
                                                isLeave=true;
                                            }
                                        });

                                        if(eventExists){
                                            event.push(<span key={i+'-event'} className="event"></span>);
                                        }
                                        if(noteExists){
                                            event.push(<span key={i+'-note'} className="note"></span>);
                                        }
                                        if(otExists){
                                            event.push(<span key={i+'-ot'} className={'ot ' + userState.user.Platoons[0]}></span>);
                                        }
                                    }

                                    // let isHoliday = userState.calendar.holiday !== null ? ( typeof userState.calendar.holiday[fullDate] === 'undefined' ? false : true ) : false;
                                    let isHoliday = userState.calendar.holiday !== null ? 
                                                        ( typeof userState.calendar.holiday[fullDate] === 'undefined' ? false 
                                                            : (userState.calendar.holiday[fullDate].filter((event) => !event.state.length || event.state && event.state.includes(userState.calendar.holidayState)).length ? true : false ) )
                                                    : false;
                                    
                                                    // console.log('date', fullDate)
                                                    // console.log('holiday', userState.calendar.holiday)
                                                    // console.log('holiday state', userState.calendar.holiday[fullDate] && userState.calendar.holiday[fullDate] ? userState.calendar.holiday[fullDate].filter((event) => event.state == userState.calendar.holidayState) : null)

                                    if( status === 1 && isHoliday ){
                                        statusClass += ' holiday';
                                    }

                                    let platoonLegend = platoon.length ? <div key={i+'-platoon-legend'} className="platoon-legend">{platoon}</div> : '';
                                    let eventLegend = event.length ? <div key={i+'-event-legend'} className="event-legend">{event}</div> : '';
                                        
                                    if (status === 1) {

                                        if (status === 1 && currDate === maxDate) {
                                            status++;
                                        }

                                        return (
                                            <td key={status + '_' + currDate} 
                                                className={statusClass} 
                                                date={fullDate}
                                                onClick={()=>changeDayEvents(fullDate, events, shift.day, shift.night, isHoliday, isDayOt, isNightOt, isDayOff, isLeave)}
                                            >
                                                 {platoonLegend}{currDate}{eventLegend}
                                            </td>
                                        )
                                    } else {
                                        return (
                                            <td key={status + '_' + currDate} className={statusClass} date={fullDate}>{platoonLegend}{currDate}{eventLegend}</td>
                                        )
                                    }
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        );
    }

    const getDay = (date) => { // get day number from 0 (monday) to 6 (sunday)
        let day = date.getDay();
        if (day === 0) day = 7; // make Sunday (0) the last day
        return day - 1;
    }

    return (
        <>
            { ! userState.calendar.isCalendarLoaded && <div className="calendar-loading gradient"></div>}
            {! userState.calendar.isCalendarLoading && userState.calendar.isCalendarLoaded && generateCalendar(props.year, props.month)}
        </>
    )
}

export default CalendarMonth;