import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useLocation } from 'react-router-dom';
import { getNotifications, getNotificationsWithoutLoading, addEndpoint } from '../slices/userSlice';

const Hook = (props) => {

    const userState = useSelector(state => state.user);  
    // const location = useLocation();
    const dispatch = useDispatch();

    const getPathName = () => {
        // return location.pathname;

        return window.location.pathname;
    }

    // useEffect(()=> {
    //     console.log('pathname', window.location.pathname)
    // }, [window.location.pathname])
    
    useEffect(()=>{
        // if( userState.isLoggedIn ){
        dispatch(getNotifications());
        // }
    }, [])

    useEffect(()=>{

        if( 
        userState.isLoggedIn &&
        ( getPathName() == '/' || getPathName() == '/alerts' )
        // !userState.event.addEvent && 
        // !userState.event.addOt && 
        // !userState.event.addDayOff && 
        // !userState.event.addAnnLeave && 
        // !userState.event.addNote &&
        // !userState.event.editEvent
        ){
        const MINUTE_MS = 60000;
        const interval = setInterval(() => {
            // console.log('event',userState.event);
            dispatch(getNotificationsWithoutLoading());
        
        }, MINUTE_MS * 0.5); // update notification every 30 seconds
        
        return () => clearInterval(interval);
        }
    // },[userState.isLoggedIn, userState.event.addEvent, userState.event.addOt, userState.event.addDayOff, userState.event.addAnnLeave, userState.event.addNote, userState.event.editEvent])
    },[userState.isLoggedIn, window.location.pathname])

    useEffect(() => {

        // console.log('isLoggedIn: ', userState.isLoggedIn);
        // console.log('notification: ', localStorage.getItem('pushnotification'));
        
        if(userState.isLoggedIn){
            let json = localStorage.getItem('pushnotification');
            if(json){
                dispatch( addEndpoint({json}));
            }
        }
    }, [window.location.pathname])
}

export default Hook;