import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MobileNav from './MobileNav'
import '../css/page.css';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { getNotifications, addEndpoint } from '../slices/userSlice';
import LoadingOverlay from 'react-loading-overlay-ts';

import { ReactComponent as IconLeft } from '../icons/ico-left.svg';
import Login from './Login';

const Page = (props) => {

    const userState = useSelector(state => state.user);
    const navigate = useNavigate();    
    const location = useLocation();
    const dispatch = useDispatch();
    
    if( props.needLogin === true && ! userState.isLoggedIn ) {
        
        return <Page mobileNav={false} needLogin={false} contentId="login" content={Login} />;
        // return <Navigate to="/login" />
    }

    const Header = () => {

        return (
            <header id={props.contentId + '-header'} >
                {props.disableBack !== 1 && <a className="go-back" href="#" onClick={(e) => {navigate(-1); e.preventDefault(); }}><IconLeft /> Back</a>}
            </header>
        );
    }

    const Content = () => {

        if(userState.notification.loading){
            return (                
                <LoadingOverlay
                    active={true}
                    // spinner={<BounceLoader />}
                    text=""
                    >                    
                </LoadingOverlay>  
            )
        }

        return (
            <div id={props.contentId + '-content'} className={'content' + ( props.mobileNav ? ' with-footer-nav' : '' )}>
                <div className="fixed-bg"></div>
                <div className="container">
                 {props.content()}
                </div>
            </div>
        );
    }

    const Footer = () => {
        return (
            <footer>
             {props.mobileNav && <MobileNav /> }
            </footer>
        )
    }

    return (
        <>
            <Header />

            <Content />
        
            <Footer />
        </>
    );
}

export default Page;