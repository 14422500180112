import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
// import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import '../css/login.css';
import { ReactComponent as IconArrow } from '../icons/ico-right-arrow.svg';
import { ReactComponent as IconUser } from '../icons/ico-user-2.svg';
import { ReactComponent as IconLock } from '../icons/ico-lock.svg';
import { ReactComponent as Logo } from '../images/logo.svg';
import {
    login,
} from '../slices/userSlice';
import { Navigate } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay-ts';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazy-load';
import ForgotUsername from './ForgotUsername';
import { Icon } from '@mui/material';
import { WA_DOMAIN, WA_SSO, WA_ACCOUNT_ID, WA_REDIRECT_URI, WA_CLIENT_ID } from '../helpers/constants';
// import BounceLoader from 'react-spinners/BounceLoader';
// import { resetLoginError } from '../slices/userSlice';

const Login = (props) => {

    const userState = useSelector(state => state.user);

    const dispatch = useDispatch();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [validated, setValidated] = useState(false);

    const [showForgotUsername, setShowForgotUsername] = useState(false);

    const emailHandler = (event) => {
        const email = event.target.value;
        setEmail(email);
    }

    const passHandler = (event) => {
        const password = event.target.value;
        setPassword(password);
    }

    const handleLogin = (event) => {

        event.preventDefault();

        if (!userState.isLoginLoading) {

            const form = event.currentTarget;

            if (form.checkValidity() === false) {
                event.stopPropagation();
            }

            setValidated(true);

            if (form.checkValidity() === true) {
                dispatch(login({
                    email,
                    password,
                }));
            }
        }
    }

    // if(WA_SSO){

    //     window.location.replace(`${WA_DOMAIN}Sys/Login/OAuthLogin?scope=contacts_me&client_id=${WA_CLIENT_ID}&response_type=authorization_code&claimed_account_id=${WA_ACCOUNT_ID}&state=WaWpIntegrationLogin&redirect_uri=${WA_REDIRECT_URI}&waLoginAction=Login`);
    //     return <></>
    //     // console.log('redirect' )
    // }

    if (userState.isLoggedIn) {
        return <Navigate to="/" />
    }

    return (
        <div className="login-wrap row">
            <LoadingOverlay
                active={userState.isLoginLoading}
                // active={true}
                // spinner={<BounceLoader />}
                text=""
            >
            </LoadingOverlay>
            <div className="logo-wrap col-12">
                <Logo />
                {/* <LazyLoad>
                    <img id="logo" src={require('../images/logo2.png')} alt="DJMIR" />
                </LazyLoad> */}
                {/* <h1 className="login-title">Welcome</h1>
                <p className="login-message">Sign into DJMIR</p> */}
            </div>
            <div className="form-wrap">
                {!WA_SSO &&
                    <Form noValidate validated={validated} className="form-login col-12" onSubmit={handleLogin}>
                        <Form.Group className="form-group mb-3_5 w-icon" controlId="login_email">
                            <IconUser />
                            <Form.Control type="email" placeholder="Username" required={true} onChange={emailHandler} />
                            <Form.Control.Feedback type="invalid">
                                Please enter valid email
                            </Form.Control.Feedback>
                        </Form.Group>

                        {/* <a className="forgot-pass" onClick={() => {
                        setShowForgotUsername(true);
                    }}>Forgot login email?</a> */}

                        <Form.Group className="form-group mb-3_5 w-icon" controlId="login_pass">
                            <IconLock />
                            <Form.Control type="password" placeholder="Password" required={true} onChange={passHandler} />
                            <Form.Control.Feedback type="invalid">
                                Please enter password
                            </Form.Control.Feedback>
                        </Form.Group>

                        {/* <a className="forgot-pass" target="_blank" rel="noreferrer" href="https://www.djmir.com.au/Sys/ResetPasswordRequest">Forgot Password?</a> */}
                        {/* <Link className="forgot-pass" to={`/external/?title=Forgot Password&url=` + btoa('https://www.djmir.com.au/Sys/ResetPasswordRequest')}>Forgot Password?</Link> */}

                        {userState.loginErrorMsg && <Alert className="mt-3" variant="danger">{userState.loginErrorMsg}</Alert>}

                        <Button className="btn-login f-width btn-w-icon" variant="primary" type="submit">
                            Sign In
                            {/* <IconArrow className="svg-ico" /> */}
                        </Button>
                    </Form>}

                {WA_SSO && <a href={`https://www.djmir.com.au/Sys/Login?ReturnUrl=` + encodeURIComponent(`${WA_DOMAIN}Sys/Login/OAuthLogin?scope=contacts_me&client_id=${WA_CLIENT_ID}&redirect_uri=${WA_REDIRECT_URI}`)}><Button className="btn-login f-width btn-w-icon" variant="primary" type="submit">
                    Sign In
                    {/* <IconArrow className="svg-ico" /> */}
                </Button></a>}

                <p className="signup-intro">Not a member yet?</p>
                <Link to="/register-step-1"><Button variant="secondary">Register Here</Button></Link>
            </div>

            <ForgotUsername showPopup={showForgotUsername} setPopup={setShowForgotUsername} />
        </div>
    );
}

export default Login;