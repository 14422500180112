import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
// import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
// import '../css/register.css';
import { ReactComponent as IconProvider } from '../icons/ico-provider.svg';
import { ReactComponent as IconCase } from '../icons/ico-case.svg';
import { ReactComponent as Logo } from '../images/logo.svg';
import {
    getServiceNumbersOptions,
    resetRegister,
    searchUserData,
} from '../slices/userSlice';
import { Navigate } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay-ts';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazy-load';
import ForgotUsername from './ForgotUsername';
import { Icon } from '@mui/material';
// import BounceLoader from 'react-spinners/BounceLoader';
// import { resetRegisterError } from '../slices/userSlice';

const RegisterStep1 = (props) => {

    const userState = useSelector(state => state.user);

    const dispatch = useDispatch();

    const [formFields, setFormFields] = useState({
        provider_number: userState.register.userData.provider_number,
        service_number: userState.register.userData.service_number,
    });

    const [validated, setValidated] = useState(false);

    const fieldChange = (e) => {

        setFormFields(formFields => ({
            ...formFields,
            [e.target.name]: e.target.value,
        }));
    }

    const handleRegister = (event) => {

        event.preventDefault();

        if (!userState.isRegisterLoading) {

            const form = event.currentTarget;

            if (form.checkValidity() === false) {
                event.stopPropagation();
            }

            setValidated(true);

            if (form.checkValidity() === true) {
                dispatch(searchUserData({
                    provider_number: formFields.provider_number,
                    service_number: formFields.service_number,
                }));
            }
        }
    }

    useEffect(() => {

        if (userState.register.isLoaded) {
            dispatch(resetRegister());
        }

    }, [userState.register.isLoaded])

    if (userState.isLoggedIn) {
        return <Navigate to="/" />
    }

    if (userState.register.isLoaded) {
        return <Navigate to="/register-step-2" />
    }

    return (
        <div className="register-wrap row">
            <LoadingOverlay
                active={userState.register.isLoading}
                // active={true}
                // spinner={<BounceLoader />}
                text=""
            >
            </LoadingOverlay>
            <div className="col-12">
                <div className="page-logo">
                    <Logo />
                </div>
            </div>
            <div className="col-12">
                {/* <h1 className="page-title extra-space">Register</h1> */}

                <div className="form-wrap center-screen">
                    <Form noValidate validated={validated} className="form-register col-12" onSubmit={handleRegister}>
                        <Form.Group className="form-group mb-3_5 w-icon" controlId="provider-number">
                            <IconProvider />
                            <Form.Control type="text" name="provider_number" placeholder="Provider number" value={formFields.provider_number} required={true} onChange={(e) => {
                                fieldChange(e);
                            }} onBlur={(e) => {
                                if (e.target.value != "")
                                    dispatch(getServiceNumbersOptions(e.target.value))
                            }} />
                            <Form.Control.Feedback type="invalid">
                                Please enter valid provider number
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-group mb-3_5 w-icon" controlId="service-number">
                            <IconCase />
                            {/* <Form.Control type="text" name="service_number" placeholder="Service number" required={true} onChange={(e) => fieldChange(e)} /> */}
                            <Form.Select name="service_number" required={false} value={formFields.service_number} onChange={(e) => fieldChange(e)}>
                                <option value="">Service number</option>
                                {userState.register.serviceNumbers.map((option, i) => {
                                    return <option key={i} value={option}>{option}</option>
                                })}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                Please enter service number
                            </Form.Control.Feedback>
                        </Form.Group>

                        {userState.register.errMsg && <Alert className="mt-3" variant="danger">{userState.register.errMsg}</Alert>}

                        <Button className="btn-register f-width btn-w-icon" variant="primary" type="submit">
                            Next
                            {/* <IconArrow className="svg-ico" /> */}
                        </Button>
                    </Form>
                </div>
            </div>
        </div>
    );
}

export default RegisterStep1;